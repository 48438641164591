import React, {useRef, useState} from 'react';
import { Button } from 'react-bootstrap';
import ActivityPast from './past.js'; // Importe o componente ActivityPast
import ActivityFall from './fall.js';
// import paypalIcon from "./Icons/paypalIcon.png"; // Importe o componente ActivityFall

const ActivityPoints = ({ goBack }) => {
    const paypalIconRef=useRef('./Icons/paypalIcon.png');

    const [page, setPage] = useState('points');

    const handlePastClick = () => {
        setPage('past');
    };

    const handleFallClick = () => {
        setPage('fall');
    };

    if (page === 'points') {
        return (
            <div className="App">
                <div className="column left" style={{alignItems: 'center',  justifyContent: 'center', height: '100vh'}}>
                    <table>
                        <tbody>
                        <tr style={{height: '5px'}}>
                            <td style={{height: '5px', hyphens: "none"}}>
                                <h3><a href="https://www.paypal.com/donate/?hosted_button_id=T5WKS4MVY7VDE" target="_blank" rel="noopener noreferrer">Doação:</a></h3>
                            </td>
                        </tr>
                        <tr style={{height: '5px'}}>

                            <td style={{height: '5px'}}>
                                <p><a href="https://www.paypal.com/donate/?hosted_button_id=T5WKS4MVY7VDE" target="_blank" rel="noopener noreferrer">
                                    <img src={require(`${paypalIconRef.current}`)} alt="Ícone do PayPal" style={{width: '200px', height: '200px'}}/>
                                </a></p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="column center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h3 style={{marginTop: '0', marginBottom: '130px' }}>SITUAÇÕES PONTUAIS</h3>
                    <Button onClick={handlePastClick} style={{ position: 'absolute', bottom: '335px',width: '250px', height: '40px', fontSize: '20px' }}>Particípios Irregulares</Button>
                    <Button onClick={handleFallClick} style={{ position: 'absolute', bottom: '290px', width: '250px', height: '40px', fontSize: '20px' }}>Supressão do E Terminal</Button>
                    <Button onClick={goBack} style={{position: 'absolute', bottom: '5px', width: '250px', height: '40px', fontSize: '20px' }}>Sair</Button>
                </div>
                <div className="column right">
                    <table>
                        <tbody>
                        <tr style={{height: '5px'}}>
                            <td style={{height: '5px', hyphens: "none"}}>
                                <h3 style={{ marginBottom: '0'}}>Particípios Irregulares:</h3>
                            </td>
                        </tr>
                        <tr style={{height: '5px'}}>

                            <td style={{height: '5px'}}>
                                <p style={{ marginBottom: '0', marginTop: '0'}}>Nesta secção, apresentamos os particípios passados irregulares. Quando os verbos apresentam dois particípios passados, um regular e outro irregular, o regular costuma ser usado em locuções verbais com o verbo "Ter"(e "Haver") e os irregulares junto com o verbo "Ser" (e "Estar",...).</p>
                                <p style={{ marginBottom: '0', marginTop: '10px'}}> No entanto, há alguns particípios passados regulares que são admitidos com o verbo "Ser" e alguns irregulares que são admitidos com o verbo "Ter". Este uso acontece mesmo com grandes autores, embora só alguns gramáticos o refiram. Deste modo, para cada verbo mostramos como cada particípio passado pode ser usado. Quando o verbo só tem o particípio passado irregular, mostramos "Ser"e "Ter" na linha dos irregulares e nada na dos regulares.</p>
                            </td>
                        </tr>
                        <tr style={{height: '5px'}}>
                            <td style={{height: '5px', hyphens: "none"}}>
                                <h3 style={{ marginBottom: '0'}}>Supressão do E Terminal:</h3>
                            </td>
                        </tr>
                        <tr style={{height: '5px'}}>
                            <td style={{height: '5px'}}>
                                <p style={{ marginBottom: '0', marginTop: '0'}}>O "e" sozinho após o "R" ou o "Z" cai algumas vezes na 3ª Pessoa do Singular do Presente do Indicativo e opcionalmente, após a 2ª Pessoa do Singular do Imperativo. No entanto, isto só acontece para os verbos terminados em -ERER, -AZER, -IZER e -UZIR. Aqui apresentamos esses casos para fácil memorização e intuição.</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    } else if (page === 'past') {
        return <ActivityPast goBack={() => setPage('points')} />;
    } else if (page === 'fall') {
        return <ActivityFall goBack={() => setPage('points')} />;
    }
};

export default ActivityPoints;
