import React, {useRef} from 'react';
import { Button, Table } from 'react-bootstrap';
// import paypalIcon from './Icons/paypalIcon.png';
// import logo from './logotipo2.png';

const ActivityInfo = ({ goBack }) => {

    const logoRef=useRef('./logotipo2.png');
    const paypalIconRef=useRef('./Icons/paypalIcon.png');

    return (
        <div className="App">
            <div className="column left" style={{alignItems: 'center',  justifyContent: 'center', height: '100vh'}}>
                <table>
                    <tbody>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px', hyphens: "none"}}>
                            <h3><a href="https://www.paypal.com/donate/?hosted_button_id=T5WKS4MVY7VDE" target="_blank" rel="noopener noreferrer">Doação:</a></h3>
                        </td>
                    </tr>
                    <tr style={{height: '5px'}}>

                        <td style={{height: '5px'}}>
                            <p><a href="https://www.paypal.com/donate/?hosted_button_id=T5WKS4MVY7VDE" target="_blank" rel="noopener noreferrer">
                                <img src={require(`${paypalIconRef.current}`)} alt="Ícone do PayPal" style={{width: '200px', height: '200px'}}/>
                            </a></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="column center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Table style={{width: '250px' }}>
                    <tbody>
                    <tr>
                        <td style={{padding: '10px 0', fontSize: '13px' }}>
                            Olá, eu sou o Leonardo Pereira, um estudante de Engenharia de Computadores e um amante da língua portuguesa e de todo o espaço lusófono.
                            <br /><br />
                            Eu verifiquei que quer nativos quer estrangeiros tinham dificuldade com as conjugações de verbos.
                            <br /><br />
                            Por isso, fiz este programa gratuito esperando que você me ajude a continuar este projeto com a sua doação.
                            <br /><br />
                            O programa ajuda a decorar a forma escrita e oral podendo você escolher entre Portugal e Brasil.
                            <br /><br />
                            Veja por favor as hiperligações abaixo onde pode encontrar informação sobre o programa e doações.
                        </td>
                    </tr>
                    <tr>
                        <td style={{ padding: '3px 0', fontSize: '14px' }}>Visite o nosso canal: <a href="https://www.youtube.com/channel/UCgT4TW_NGL6sFU744v0y_zQ" target="_blank" rel="noopener noreferrer">https://www.youtube.com/channel/...</a></td>
                    </tr>
                    <tr>
                        <td style={{ fontSize: '14px' }}>Faça doação por PayPal/Cartão ou PIX:  <a href="https://www.paypal.com/donate/?hosted_button_id=T5WKS4MVY7VDE" target="_blank" rel="noopener noreferrer">https://www.paypal.com/donate/...</a></td>
                    </tr>
                    <tr>
                        <td style={{ padding: '10px 0', fontSize: '14px' }}>PIX/Cel:67981990829</td>
                    </tr>
                    </tbody>
                </Table>
                <Button onClick={goBack} style={{position: 'absolute', bottom: '5px', width: '250px', height: '40px', fontSize: '20px'}}>Sair</Button>
            </div>
            <div className="column right" style={{alignItems: 'center',  justifyContent: 'center', height: '100vh'}}>
                <table>
                    <tbody>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px', hyphens: "none"}}>
                            <h3><a href="https://www.youtube.com/channel/UCgT4TW_NGL6sFU744v0y_zQ" target="_blank" rel="noopener noreferrer">Canal:</a></h3>
                        </td>
                    </tr>
                    <tr style={{height: '5px'}}>

                        <td style={{height: '5px'}}>
                            <p><a href="https://www.youtube.com/channel/UCgT4TW_NGL6sFU744v0y_zQ" target="_blank" rel="noopener noreferrer">
                                <img src={require(`${logoRef.current}`)} alt="Ícone do Canal" style={{width: '200px', height: '200px'}}/>
                            </a></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ActivityInfo;
