import React, {useEffect, useRef, useState} from 'react';
import {Button, Table} from 'react-bootstrap';
import ActivityConfig from './config'; // Certifique-se de importar o componente ActivityConfig
import ActivityInfo from './info'; // Certifique-se de importar o componente ActivityInfo
import ActivityVerbs from './verbs'; // Importe o componente ActivityVerbs
import ActivityPoints from './points'; // Importe o componente ActivityPoints
import './App.css'
// import logo from './logotipo2.png'; // O caminho para a imagem
// import paypalIcon from "./Icons/paypalIcon.png";
import helpIcon from "./Icons/helpIcon.png";

const MainActivity = () => {
  const logoRef=useRef('./logotipo2.png');
  const paypalIconRef=useRef('./Icons/paypalIcon.png');
  //const helpIconRef= useRef(require ("./Icons/helpIcon.png"));
  useEffect(() => {
    //Obs: reescrever o arquivo config para o caso de corrupções ou novas versões -I
    let setConfig = {
      NumeroAudios: '0',
      PaisAudio: 'Brasil',
      LerTempo: 'Uma Vez',
      VelocidadeAudio: '100'
    };

    console.log(setConfig);
    const savedConfig = localStorage.getItem('config');
    console.log(1);

    if (savedConfig) {
      setConfig=JSON.parse(savedConfig);
      if(setConfig.NumeroAudios===undefined ){
        setConfig.NumeroAudios='0';
      } else if(setConfig.NumeroAudios===null){
        setConfig.NumeroAudios='0';
      } else if(setConfig.NumeroAudios===''){
        setConfig.NumeroAudios='0';
      }

      if(setConfig.PaisAudio===undefined ){
        setConfig.PaisAudio='Brasil';
      } else if(setConfig.PaisAudio===null){
        setConfig.PaisAudio='Brasil';
      } else if(setConfig.PaisAudio===''){
        setConfig.PaisAudio='Brasil';
      }

      if(setConfig.LerTempo===undefined ){
        setConfig.LerTempo='Uma Vez';
      } else if(setConfig.LerTempo===null){
        setConfig.LerTempo='Uma Vez';
      } else if(setConfig.LerTempo===''){
        setConfig.LerTempo='Uma Vez';
      }

      if(setConfig.VelocidadeAudio===undefined ){
        setConfig.VelocidadeAudio='100';
      } else if(setConfig.VelocidadeAudio===null){
        setConfig.VelocidadeAudio='100';
      } else if(setConfig.VelocidadeAudio===''){
        setConfig.VelocidadeAudio='100';
      }
    }

    console.log(4);
    localStorage.setItem('config', JSON.stringify(setConfig));
    console.log(setConfig);
    //Obs: reescrever o arquivo config para o caso de corrupções ou novas versões -F
  }, []); // Dependências vazias significam que isso só será executado na montagem do componente

  const [page, setPage] = useState('main');

  const handleButton1Click = () => {
    setPage('config');
  };

  const handleButton2Click = () => {
    // Navegue para a página de verbos
    setPage('verbs');
  };

  const handleButton10Click = () => {
    // Navegue para a página de pontos
    setPage('points');
  };

  const handleHelpClick1 = () => {
    // Navegue para a página de ajuda
    window.open('https://www.youtube.com/watch?v=u11TydhiNzE', '_blank');
  };

  const handleAppDownloadClick = () => {
    // window.open('https://www.amazon.com.br/Leonardo-Rafael-Verbos-PT/dp/B0D2LT7L47/ref=sr_1_1?__mk_pt_BR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=1WDJONLDBFL9B&dib=eyJ2IjoiMSJ9.UDQ8YGsxvy68vdZQzk6OzkjzOCNg-uCF9iIZpYdAfVLFSDyuSwuCSWzDD2p2EmyD1o5UVM-mAvTqmzAr-XXIEw.zvVozDP9DC7EnIQgmGeG3VrmpIlEZ5fNKs4EtiZP294&dib_tag=se&keywords=Verbos+PT&qid=1716486415&s=mobile-apps&sprefix=verbos+pt%2Cmobile-apps%2C296&sr=1-1', '_blank');
    window.open('https://play.google.com/store/apps/details?id=com.apkomp.verbospt', '_blank');
  };

  const handleInfoClick = () => {
    setPage('info');
  };

  if (page === 'main') {
    return (
        <div className="App">
          <div className="column left" style={{alignItems: 'center',  justifyContent: 'center', height: '100vh'}}>
            <h2>Em construção...</h2>
            <table>
              <tbody>
              <tr style={{height: '5px'}}>
                <td style={{height: '5px', hyphens: "none"}}>
                  <h3><a href="https://www.paypal.com/donate/?hosted_button_id=T5WKS4MVY7VDE" target="_blank" rel="noopener noreferrer">Doação:</a></h3>
                </td>
              </tr>
              <tr style={{height: '5px'}}>

                <td style={{height: '5px'}}>
                  <p><a href="https://www.paypal.com/donate/?hosted_button_id=T5WKS4MVY7VDE" target="_blank" rel="noopener noreferrer">
                    <img src={require(`${paypalIconRef.current}`)} alt="Ícone do PayPal" style={{width: '200px', height: '200px'}}/>
                  </a></p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className="column center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h3 style={{marginTop: '0' }}>MEMORIZADOR DE VERBOS</h3>
            <img src={require(`${logoRef.current}`)} alt="Logo" style={{marginBottom: '28px', width: '250px', height: '250px' }} />
            <Button onClick={handleButton1Click} style={{position: 'absolute', bottom: '185px', width: '250px', height: '40px', fontSize: '20px' }}>Configurações</Button>
            <Button onClick={handleButton2Click} style={{position: 'absolute', bottom: '140px',width: '250px', height: '40px', fontSize: '20px' }}>Conjugações</Button>
            <Button onClick={handleButton10Click} style={{position: 'absolute', bottom: '95px', width: '250px', height: '40px', fontSize: '20px' }}>Situações Pontuais</Button>
            <Button onClick={handleInfoClick} style={{position: 'absolute', bottom: '50px', width: '250px', height: '40px', fontSize: '20px' }}>Informações</Button>
            <Table style={{ position: 'absolute', bottom: '1px'}}>
              <tbody>
              <tr>
                <td style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button id="ajudaA" onClick={handleHelpClick1} style={{ width: '40px', height: '40px', fontSize: '18px', backgroundImage: `url(${helpIcon})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></Button>
                </td>
                <td> <Button onClick={handleAppDownloadClick} style={{ width: '166px', height: '40px', fontSize: '20px',  backgroundPosition: 'center' }}>Baixar APP</Button> </td>
                <td style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button id="ajudaB" onClick={handleHelpClick1} style={{ width: '40px', height: '40px', fontSize: '18px', backgroundImage: `url(${helpIcon})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></Button>
                </td>
              </tr>
              </tbody>
            </Table>
          </div>
          <div className="column right">
            <table>
              <tbody>
              <tr style={{height: '5px'}}>
                <td style={{height: '5px', hyphens: "none"}}>
                  <h3 style={{ marginBottom: '0'}}>Configurações:</h3>
                </td>
              </tr>
              <tr style={{height: '5px'}}>
                <td style={{height: '5px'}}>
                  <p style={{ marginBottom: '0', marginTop: '0'}}>Você poderá configurar as interações do aplicativo de acordo com a sua preferência. Este botão é especialmente útil se você quiser ouvir as conjugações.</p>
                </td>
              </tr>
              <tr style={{height: '5px'}}>
                <td style={{height: '5px', hyphens: "none"}}>
                  <h3 style={{ marginBottom: '0'}}>Conjugações:</h3>
                </td>
              </tr>
              <tr style={{height: '5px'}}>
                <td style={{height: '5px'}}>
                  <p style={{ marginBottom: '0', marginTop: '0'}}>Você verá vários botões com tipos de verbos. Cada um desses tipos terá um número específico de paradigmas, que são verbos que servem de uma base para a conjugação de outros verbos, quer sejam regulares ou irregulares.</p>
                </td>
              </tr>
              <tr style={{height: '5px'}}>
                <td style={{height: '5px', hyphens: "none"}}>
                  <h3 style={{ marginBottom: '0'}}>Situações Pontuais:</h3>
                </td>
              </tr>
              <tr style={{height: '5px'}}>
                <td style={{height: '5px'}}>
                  <p style={{ marginBottom: '0', marginTop: '0'}}>Existem situações excecionais isoladas. Alguns verbos perdem o "E" final em algumas conjugações; outros têm o particípio passado irregular; e outros dois particípios passados: um regular e outro regular. Estas características são estudadas aqui de forma especial, pois os verbos paradigmáticos para estes aspectos são diferentes dos verbos paradigmáticos mostrados para as Conjugações em geral. Este aplicativo é feito para memorizar e por isso tentamos não multiplicar o número de paradigmas.</p>
                </td>
              </tr>
              <tr style={{height: '5px'}}>
                <td style={{height: '5px', hyphens: "none"}}>
                  <h3 style={{ marginBottom: '0'}}>Informações:</h3>
                </td>
              </tr>
              <tr style={{height: '5px'}}>
                <td style={{height: '5px'}}>
                  <p style={{ marginBottom: '0', marginTop: '0'}}>Você verá mais informações sobre o aplicativo e algumas hiperligações.</p>
                </td>
              </tr>
              <tr style={{height: '5px'}}>
                <td style={{height: '5px', hyphens: "none"}}>
                  <h3 style={{ marginBottom: '0'}}>Ajuda:</h3>
                </td>
              </tr>
              <tr style={{height: '5px'}}>
                <td style={{height: '5px'}}>
                  <p style={{ marginBottom: '0', marginTop: '0'}}>Acesso a um vídeo explicativo, sobre este aplicativo, localizado no nosso canal do YouTube.</p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
    );
  } else if (page === 'config') {
    return <ActivityConfig goBack={() => setPage('main')} />;
  } else if (page === 'info') {
    return <ActivityInfo goBack={() => setPage('main')} />;
  } else if (page === 'verbs') {
    return <ActivityVerbs goBack={() => setPage('main')} />;
  } else if (page === 'points') {
    return <ActivityPoints goBack={() => setPage('main')} />;
  }
};


export default MainActivity;
