let ActivityConfig = JSON.parse(localStorage.getItem('config'));
export const verbos1 = [
    "v11_1,CHAMAR (call) (-AR),PRETÉRITO PERFEITO",
    "v11_2,CHAMAR (call) (-AR),PRESENTE",
    "v11_3,CHAMAR (call) (-AR),FUTURO",
    "v11_4,CHAMAR (call) (-AR),PRETÉRITO MAIS-QUE-PERFEITO",
    "v11_5,CHAMAR (call) (-AR),PRETÉRITO IMPERFEITO",
    "v11_6,CHAMAR (call) (-AR),@CONDICIONAL",
    "v11_7,CHAMAR (call) (-AR),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v11_8,CHAMAR (call) (-AR),PRESENTE @CONJUNTIVO",
    "v11_9,CHAMAR (call) (-AR),FUTURO @CONJUNTIVO",
    "v11_i,CHAMAR (call) (-AR),IMPERATIVO RESTRITO",
    "v11_n,CHAMAR (call) (-AR),INFINITIVO PESSOAL",
    "v11_p,CHAMAR (call) (-AR),FORMAS NOMINAIS",
    "v12_1,VENDER (sell) (-ER),PRETÉRITO PERFEITO",
    "v12_2,VENDER (sell) (-ER),PRESENTE",
    "v12_3,VENDER (sell) (-ER),FUTURO",
    "v12_4,VENDER (sell) (-ER),PRETÉRITO MAIS-QUE-PERFEITO",
    "v12_5,VENDER (sell) (-ER),PRETÉRITO IMPERFEITO",
    "v12_6,VENDER (sell) (-ER),@CONDICIONAL",
    "v12_7,VENDER (sell) (-ER),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v12_8,VENDER (sell) (-ER),PRESENTE @CONJUNTIVO",
    "v12_9,VENDER (sell) (-ER),FUTURO @CONJUNTIVO",
    "v12_i,VENDER (sell) (-ER),IMPERATIVO RESTRITO",
    "v12_n,VENDER (sell) (-ER),INFINITIVO PESSOAL",
    "v12_p,VENDER (sell) (-ER),FORMAS NOMINAIS",
    "v13_1,PARTIR (leave) (-IR),PRETÉRITO PERFEITO",
    "v13_2,PARTIR (leave) (-IR),PRESENTE",
    "v13_3,PARTIR (leave) (-IR),FUTURO",
    "v13_4,PARTIR (leave) (-IR),PRETÉRITO MAIS-QUE-PERFEITO",
    "v13_5,PARTIR (leave) (-IR),PRETÉRITO IMPERFEITO",
    "v13_6,PARTIR (leave) (-IR),@CONDICIONAL",
    "v13_7,PARTIR (leave) (-IR),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v13_8,PARTIR (leave) (-IR),PRESENTE @CONJUNTIVO",
    "v13_9,PARTIR (leave) (-IR),FUTURO @CONJUNTIVO",
    "v13_i,PARTIR (leave) (-IR),IMPERATIVO RESTRITO",
    "v13_n,PARTIR (leave) (-IR),INFINITIVO PESSOAL",
    "v13_p,PARTIR (leave) (-IR),FORMAS NOMINAIS",
];

export const verbos2 = [
    "v21_1,PASSEAR (stroll) (-EAR),PRETÉRITO PERFEITO",
    "v21_2,PASSEAR (stroll) (-EAR),PRESENTE",
    "v21_3,PASSEAR (stroll) (-EAR),FUTURO",
    "v21_4,PASSEAR (stroll) (-EAR),PRETÉRITO MAIS-QUE-PERFEITO",
    "v21_5,PASSEAR (stroll) (-EAR),PRETÉRITO IMPERFEITO",
    "v21_6,PASSEAR (stroll) (-EAR),@CONDICIONAL",
    "v21_7,PASSEAR (stroll) (-EAR),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v21_8,PASSEAR (stroll) (-EAR),PRESENTE @CONJUNTIVO",
    "v21_9,PASSEAR (stroll) (-EAR),FUTURO @CONJUNTIVO",
    "v21_i,PASSEAR (stroll) (-EAR),IMPERATIVO RESTRITO",
    "v21_n,PASSEAR (stroll) (-EAR),INFINITIVO PESSOAL",
    "v21_p,PASSEAR (stroll) (-EAR),FORMAS NOMINAIS",
    "v22_1,MOER (grind) (-OER),PRETÉRITO PERFEITO",
    "v22_2,MOER (grind) (-OER),PRESENTE",
    "v22_3,MOER (grind) (-OER),FUTURO",
    "v22_4,MOER (grind) (-OER),PRETÉRITO MAIS-QUE-PERFEITO",
    "v22_5,MOER (grind) (-OER),PRETÉRITO IMPERFEITO",
    "v22_6,MOER (grind) (-OER),@CONDICIONAL",
    "v22_7,MOER (grind) (-OER),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v22_8,MOER (grind) (-OER),PRESENTE @CONJUNTIVO",
    "v22_9,MOER (grind) (-OER),FUTURO @CONJUNTIVO",
    "v22_i,MOER (grind) (-OER),IMPERATIVO RESTRITO",
    "v22_n,MOER (grind) (-OER),INFINITIVO PESSOAL",
    "v22_p,MOER (grind) (-OER),FORMAS NOMINAIS",
    "v23_1,ATRAIR (attract) (-AIR),PRETÉRITO PERFEITO",
    "v23_2,ATRAIR (attract) (-AIR),PRESENTE",
    "v23_3,ATRAIR (attract) (-AIR),FUTURO",
    "v23_4,ATRAIR (attract) (-AIR),PRETÉRITO MAIS-QUE-PERFEITO",
    "v23_5,ATRAIR (attract) (-AIR),PRETÉRITO IMPERFEITO",
    "v23_6,ATRAIR (attract) (-AIR),@CONDICIONAL",
    "v23_7,ATRAIR (attract) (-AIR),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v23_8,ATRAIR (attract) (-AIR),PRESENTE @CONJUNTIVO",
    "v23_9,ATRAIR (attract) (-AIR),FUTURO @CONJUNTIVO",
    "v23_i,ATRAIR (attract) (-AIR),IMPERATIVO RESTRITO",
    "v23_n,ATRAIR (attract) (-AIR),INFINITIVO PESSOAL",
    "v23_p,ATRAIR (attract) (-AIR),FORMAS NOMINAIS",
    "v24_1,ATRIBUIR (assign) (-UIR),PRETÉRITO PERFEITO",
    "v24_2,ATRIBUIR (assign) (-UIR),PRESENTE",
    "v24_3,ATRIBUIR (assign) (-UIR),FUTURO",
    "v24_4,ATRIBUIR (assign) (-UIR),PRETÉRITO MAIS-QUE-PERFEITO",
    "v24_5,ATRIBUIR (assign) (-UIR),PRETÉRITO IMPERFEITO",
    "v24_6,ATRIBUIR (assign) (-UIR),@CONDICIONAL",
    "v24_7,ATRIBUIR (assign) (-UIR),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v24_8,ATRIBUIR (assign) (-UIR),PRESENTE @CONJUNTIVO",
    "v24_9,ATRIBUIR (assign) (-UIR),FUTURO @CONJUNTIVO",
    "v24_i,ATRIBUIR (assign) (-UIR),IMPERATIVO RESTRITO",
    "v24_n,ATRIBUIR (assign) (-UIR),INFINITIVO PESSOAL",
    "v24_p,ATRIBUIR (assign) (-UIR),FORMAS NOMINAIS",
    "v25_1,ARGUIR (argue) (-GUIR),PRETÉRITO PERFEITO",
    "v25_2,ARGUIR (argue) (-GUIR),PRESENTE",
    "v25_3,ARGUIR (argue) (-GUIR),FUTURO",
    "v25_4,ARGUIR (argue) (-GUIR),PRETÉRITO MAIS-QUE-PERFEITO",
    "v25_5,ARGUIR (argue) (-GUIR),PRETÉRITO IMPERFEITO",
    "v25_6,ARGUIR (argue) (-GUIR),@CONDICIONAL",
    "v25_7,ARGUIR (argue) (-GUIR),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v25_8,ARGUIR (argue) (-GUIR),PRESENTE @CONJUNTIVO",
    "v25_9,ARGUIR (argue) (-GUIR),FUTURO @CONJUNTIVO",
    "v25_i,ARGUIR (argue) (-GUIR),IMPERATIVO RESTRITO",
    "v25_n,ARGUIR (argue) (-GUIR),INFINITIVO PESSOAL",
    "v25_p,ARGUIR (argue) (-GUIR),FORMAS NOMINAIS",
];

export const verbos3 = [
    "v30_1,DESAGUAR (flow) (@),PRETÉRITO PERFEITO",
    "v30_2,DESAGUAR (flow) (@),PRESENTE",
    "v30_3,DESAGUAR (flow) (@),FUTURO",
    "v30_4,DESAGUAR (flow) (@),PRETÉRITO MAIS-QUE-PERFEITO",
    "v30_5,DESAGUAR (flow) (@),PRETÉRITO IMPERFEITO",
    "v30_6,DESAGUAR (flow) (@),@CONDICIONAL",
    "v30_7,DESAGUAR (flow) (@),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v30_8,DESAGUAR (flow) (@),PRESENTE @CONJUNTIVO",
    "v30_9,DESAGUAR (flow) (@),FUTURO @CONJUNTIVO",
    "v30_i,DESAGUAR (flow) (@),IMPERATIVO RESTRITO",
    "v30_n,DESAGUAR (flow) (@),INFINITIVO PESSOAL",
    "v30_p,DESAGUAR (flow) (@),FORMAS NOMINAIS",
    "v31_1,ADEQUAR (adapt) (@),PRETÉRITO PERFEITO",
    "v31_2,ADEQUAR (adapt) (@),PRESENTE",
    "v31_3,ADEQUAR (adapt) (@),FUTURO",
    "v31_4,ADEQUAR (adapt) (@),PRETÉRITO MAIS-QUE-PERFEITO",
    "v31_5,ADEQUAR (adapt) (@),PRETÉRITO IMPERFEITO",
    "v31_6,ADEQUAR (adapt) (@),@CONDICIONAL",
    "v31_7,ADEQUAR (adapt) (@),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v31_8,ADEQUAR (adapt) (@),PRESENTE @CONJUNTIVO",
    "v31_9,ADEQUAR (adapt) (@),FUTURO @CONJUNTIVO",
    "v31_i,ADEQUAR (adapt) (@),IMPERATIVO RESTRITO",
    "v31_n,ADEQUAR (adapt) (@),INFINITIVO PESSOAL",
    "v31_p,ADEQUAR (adapt) (@),FORMAS NOMINAIS",
    "v32_1,DELINQUIR (commit_an_ofense) (@),PRETÉRITO PERFEITO",
    "v32_2,DELINQUIR (commit_an_ofense) (@),PRESENTE",
    "v32_3,DELINQUIR (commit_an_ofense) (@),FUTURO",
    "v32_4,DELINQUIR (commit_an_ofense) (@),PRETÉRITO MAIS-QUE-PERFEITO",
    "v32_5,DELINQUIR (commit_an_ofense) (@),PRETÉRITO IMPERFEITO",
    "v32_6,DELINQUIR (commit_an_ofense) (@),@CONDICIONAL",
    "v32_7,DELINQUIR (commit_an_ofense) (@),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v32_8,DELINQUIR (commit_an_ofense) (@),PRESENTE @CONJUNTIVO",
    "v32_9,DELINQUIR (commit_an_ofense) (@),FUTURO @CONJUNTIVO",
    "v32_i,DELINQUIR (commit_an_ofense) (@),IMPERATIVO RESTRITO",
    "v32_n,DELINQUIR (commit_an_ofense) (@),INFINITIVO PESSOAL",
    "v32_p,DELINQUIR (commit_an_ofense) (@),FORMAS NOMINAIS",
    "v33_1,COMERCIAR (trade) (@),PRETÉRITO PERFEITO",
    "v33_2,COMERCIAR (trade) (@),PRESENTE",
    "v33_3,COMERCIAR (trade) (@),FUTURO",
    "v33_4,COMERCIAR (trade) (@),PRETÉRITO MAIS-QUE-PERFEITO",
    "v33_5,COMERCIAR (trade) (@),PRETÉRITO IMPERFEITO",
    "v33_6,COMERCIAR (trade) (@),@CONDICIONAL",
    "v33_7,COMERCIAR (trade) (@),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v33_8,COMERCIAR (trade) (@),PRESENTE @CONJUNTIVO",
    "v33_9,COMERCIAR (trade) (@),FUTURO @CONJUNTIVO",
    "v33_i,COMERCIAR (trade) (@),IMPERATIVO RESTRITO",
    "v33_n,COMERCIAR (trade) (@),INFINITIVO PESSOAL",
    "v33_p,COMERCIAR (trade) (@),FORMAS NOMINAIS",
    "v34_1,CONSTRUIR (build) (@),PRETÉRITO PERFEITO",
    "v34_2,CONSTRUIR (build) (@),PRESENTE",
    "v34_3,CONSTRUIR (build) (@),FUTURO",
    "v34_4,CONSTRUIR (build) (@),PRETÉRITO MAIS-QUE-PERFEITO",
    "v34_5,CONSTRUIR (build) (@),PRETÉRITO IMPERFEITO",
    "v34_6,CONSTRUIR (build) (@),@CONDICIONAL",
    "v34_7,CONSTRUIR (build) (@),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v34_8,CONSTRUIR (build) (@),PRESENTE @CONJUNTIVO",
    "v34_9,CONSTRUIR (build) (@),FUTURO @CONJUNTIVO",
    "v34_i,CONSTRUIR (build) (@),IMPERATIVO RESTRITO",
    "v34_n,CONSTRUIR (build) (@),INFINITIVO PESSOAL",
    "v34_p,CONSTRUIR (build) (@),FORMAS NOMINAIS",
    "v35_1,EMERGIR (emerge) (@),PRETÉRITO PERFEITO",
    "v35_2,EMERGIR (emerge) (@),PRESENTE",
    "v35_3,EMERGIR (emerge) (@),FUTURO",
    "v35_4,EMERGIR (emerge) (@),PRETÉRITO MAIS-QUE-PERFEITO",
    "v35_5,EMERGIR (emerge) (@),PRETÉRITO IMPERFEITO",
    "v35_6,EMERGIR (emerge) (@),@CONDICIONAL",
    "v35_7,EMERGIR (emerge) (@),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v35_8,EMERGIR (emerge) (@),PRESENTE @CONJUNTIVO",
    "v35_9,EMERGIR (emerge) (@),FUTURO @CONJUNTIVO",
    "v35_i,EMERGIR (emerge) (@),IMPERATIVO RESTRITO",
    "v35_n,EMERGIR (emerge) (@),INFINITIVO PESSOAL",
    "v35_p,EMERGIR (emerge) (@),FORMAS NOMINAIS",
    "v36_1,EXPLODIR (explode) (@),PRETÉRITO PERFEITO",
    "v36_2,EXPLODIR (explode) (@),PRESENTE",
    "v36_3,EXPLODIR (explode) (@),FUTURO",
    "v36_4,EXPLODIR (explode) (@),PRETÉRITO MAIS-QUE-PERFEITO",
    "v36_5,EXPLODIR (explode) (@),PRETÉRITO IMPERFEITO",
    "v36_6,EXPLODIR (explode) (@),@CONDICIONAL",
    "v36_7,EXPLODIR (explode) (@),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v36_8,EXPLODIR (explode) (@),PRESENTE @CONJUNTIVO",
    "v36_9,EXPLODIR (explode) (@),FUTURO @CONJUNTIVO",
    "v36_i,EXPLODIR (explode) (@),IMPERATIVO RESTRITO",
    "v36_n,EXPLODIR (explode) (@),INFINITIVO PESSOAL",
    "v36_p,EXPLODIR (explode) (@),FORMAS NOMINAIS",
];

export const verbos4 = [
    "v41_1,ANSIAR (long_for) (-IAR¹),PRETÉRITO PERFEITO",
    "v41_2,ANSIAR (long_for) (-IAR¹),PRESENTE",
    "v41_3,ANSIAR (long_for) (-IAR¹),FUTURO",
    "v41_4,ANSIAR (long_for) (-IAR¹),PRETÉRITO MAIS-QUE-PERFEITO",
    "v41_5,ANSIAR (long_for) (-IAR¹),PRETÉRITO IMPERFEITO",
    "v41_6,ANSIAR (long_for) (-IAR¹),@CONDICIONAL",
    "v41_7,ANSIAR (long_for) (-IAR¹),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v41_8,ANSIAR (long_for) (-IAR¹),PRESENTE @CONJUNTIVO",
    "v41_9,ANSIAR (long_for) (-IAR¹),FUTURO @CONJUNTIVO",
    "v41_i,ANSIAR (long_for) (-IAR¹),IMPERATIVO RESTRITO",
    "v41_n,ANSIAR (long_for) (-IAR¹),INFINITIVO PESSOAL",
    "v41_p,ANSIAR (long_for) (-IAR¹),FORMAS NOMINAIS",
    "v42_1,SENTIR (feel) (-E-IR¹),PRETÉRITO PERFEITO",
    "v42_2,SENTIR (feel) (-E-IR¹),PRESENTE",
    "v42_3,SENTIR (feel) (-E-IR¹),FUTURO",
    "v42_4,SENTIR (feel) (-E-IR¹),PRETÉRITO MAIS-QUE-PERFEITO",
    "v42_5,SENTIR (feel) (-E-IR¹),PRETÉRITO IMPERFEITO",
    "v42_6,SENTIR (feel) (-E-IR¹),@CONDICIONAL",
    "v42_7,SENTIR (feel) (-E-IR¹),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v42_8,SENTIR (feel) (-E-IR¹),PRESENTE @CONJUNTIVO",
    "v42_9,SENTIR (feel) (-E-IR¹),FUTURO @CONJUNTIVO",
    "v42_i,SENTIR (feel) (-E-IR¹),IMPERATIVO RESTRITO",
    "v42_n,SENTIR (feel) (-E-IR¹),INFINITIVO PESSOAL",
    "v42_p,SENTIR (feel) (-E-IR¹),FORMAS NOMINAIS",
    "v43_1,AGREDIR (hit) (-E-IR²),PRETÉRITO PERFEITO",
    "v43_2,AGREDIR (hit) (-E-IR²),PRESENTE",
    "v43_3,AGREDIR (hit) (-E-IR²),FUTURO",
    "v43_4,AGREDIR (hit) (-E-IR²),PRETÉRITO MAIS-QUE-PERFEITO",
    "v43_5,AGREDIR (hit) (-E-IR²),PRETÉRITO IMPERFEITO",
    "v43_6,AGREDIR (hit) (-E-IR²),@CONDICIONAL",
    "v43_7,AGREDIR (hit) (-E-IR²),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v43_8,AGREDIR (hit) (-E-IR²),PRESENTE @CONJUNTIVO",
    "v43_9,AGREDIR (hit) (-E-IR²),FUTURO @CONJUNTIVO",
    "v43_i,AGREDIR (hit) (-E-IR²),IMPERATIVO RESTRITO",
    "v43_n,AGREDIR (hit) (-E-IR²),INFINITIVO PESSOAL",
    "v43_p,AGREDIR (hit) (-E-IR²),FORMAS NOMINAIS",
    "v44_1,FRIGIR (fry) (-I-IR¹),PRETÉRITO PERFEITO",
    "v44_2,FRIGIR (fry) (-I-IR¹),PRESENTE",
    "v44_3,FRIGIR (fry) (-I-IR¹),FUTURO",
    "v44_4,FRIGIR (fry) (-I-IR¹),PRETÉRITO MAIS-QUE-PERFEITO",
    "v44_5,FRIGIR (fry) (-I-IR¹),PRETÉRITO IMPERFEITO",
    "v44_6,FRIGIR (fry) (-I-IR¹),@CONDICIONAL",
    "v44_7,FRIGIR (fry) (-I-IR¹),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v44_8,FRIGIR (fry) (-I-IR¹),PRESENTE @CONJUNTIVO",
    "v44_9,FRIGIR (fry) (-I-IR¹),FUTURO @CONJUNTIVO",
    "v44_i,FRIGIR (fry) (-I-IR¹),IMPERATIVO RESTRITO",
    "v44_n,FRIGIR (fry) (-I-IR¹),INFINITIVO PESSOAL",
    "v44_p,FRIGIR (fry) (-I-IR¹),FORMAS NOMINAIS",
    "v45_1,DORMIR (sleep) (-O-IR¹),PRETÉRITO PERFEITO",
    "v45_2,DORMIR (sleep) (-O-IR¹),PRESENTE",
    "v45_3,DORMIR (sleep) (-O-IR¹),FUTURO",
    "v45_4,DORMIR (sleep) (-O-IR¹),PRETÉRITO MAIS-QUE-PERFEITO",
    "v45_5,DORMIR (sleep) (-O-IR¹),PRETÉRITO IMPERFEITO",
    "v45_6,DORMIR (sleep) (-O-IR¹),@CONDICIONAL",
    "v45_7,DORMIR (sleep) (-O-IR¹),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v45_8,DORMIR (sleep) (-O-IR¹),PRESENTE @CONJUNTIVO",
    "v45_9,DORMIR (sleep) (-O-IR¹),FUTURO @CONJUNTIVO",
    "v45_i,DORMIR (sleep) (-O-IR¹),IMPERATIVO RESTRITO",
    "v45_n,DORMIR (sleep) (-O-IR¹),INFINITIVO PESSOAL",
    "v45_p,DORMIR (sleep) (-O-IR¹),FORMAS NOMINAIS",
    "v46_1,POLIR (polish) (-O-IR²),PRETÉRITO PERFEITO",
    "v46_2,POLIR (polish) (-O-IR²),PRESENTE",
    "v46_3,POLIR (polish) (-O-IR²),FUTURO",
    "v46_4,POLIR (polish) (-O-IR²),PRETÉRITO MAIS-QUE-PERFEITO",
    "v46_5,POLIR (polish) (-O-IR²),PRETÉRITO IMPERFEITO",
    "v46_6,POLIR (polish) (-O-IR²),@CONDICIONAL",
    "v46_7,POLIR (polish) (-O-IR²),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v46_8,POLIR (polish) (-O-IR²),PRESENTE @CONJUNTIVO",
    "v46_9,POLIR (polish) (-O-IR²),FUTURO @CONJUNTIVO",
    "v46_i,POLIR (polish) (-O-IR²),IMPERATIVO RESTRITO",
    "v46_n,POLIR (polish) (-O-IR²),INFINITIVO PESSOAL",
    "v46_p,POLIR (polish) (-O-IR²),FORMAS NOMINAIS",
    "v47_1,SACUDIR (shake) (-U-IR¹),PRETÉRITO PERFEITO",
    "v47_2,SACUDIR (shake) (-U-IR¹),PRESENTE",
    "v47_3,SACUDIR (shake) (-U-IR¹),FUTURO",
    "v47_4,SACUDIR (shake) (-U-IR¹),PRETÉRITO MAIS-QUE-PERFEITO",
    "v47_5,SACUDIR (shake) (-U-IR¹),PRETÉRITO IMPERFEITO",
    "v47_6,SACUDIR (shake) (-U-IR¹),@CONDICIONAL",
    "v47_7,SACUDIR (shake) (-U-IR¹),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v47_8,SACUDIR (shake) (-U-IR¹),PRESENTE @CONJUNTIVO",
    "v47_9,SACUDIR (shake) (-U-IR¹),FUTURO @CONJUNTIVO",
    "v47_i,SACUDIR (shake) (-U-IR¹),IMPERATIVO RESTRITO",
    "v47_n,SACUDIR (shake) (-U-IR¹),INFINITIVO PESSOAL",
    "v47_p,SACUDIR (shake) (-U-IR¹),FORMAS NOMINAIS",
    "v48_1,CERZIR (darn) (-E-IR¹²),PRETÉRITO PERFEITO",
    "v48_2,CERZIR (darn) (-E-IR¹²),PRESENTE",
    "v48_3,CERZIR (darn) (-E-IR¹²),FUTURO",
    "v48_4,CERZIR (darn) (-E-IR¹²),PRETÉRITO MAIS-QUE-PERFEITO",
    "v48_5,CERZIR (darn) (-E-IR¹²),PRETÉRITO IMPERFEITO",
    "v48_6,CERZIR (darn) (-E-IR¹²),@CONDICIONAL",
    "v48_7,CERZIR (darn) (-E-IR¹²),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v48_8,CERZIR (darn) (-E-IR¹²),PRESENTE @CONJUNTIVO",
    "v48_9,CERZIR (darn) (-E-IR¹²),FUTURO @CONJUNTIVO",
    "v48_i,CERZIR (darn) (-E-IR¹²),IMPERATIVO RESTRITO",
    "v48_n,CERZIR (darn) (-E-IR¹²),INFINITIVO PESSOAL",
    "v48_p,CERZIR (darn) (-E-IR¹²),FORMAS NOMINAIS",
];

export const verbos5 = [
    "v51_1,PERDER (lose) (*),PRETÉRITO PERFEITO",
    "v51_2,PERDER (lose) (*),PRESENTE",
    "v51_3,PERDER (lose) (*),FUTURO",
    "v51_4,PERDER (lose) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v51_5,PERDER (lose) (*),PRETÉRITO IMPERFEITO",
    "v51_6,PERDER (lose) (*),@CONDICIONAL",
    "v51_7,PERDER (lose) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v51_8,PERDER (lose) (*),PRESENTE @CONJUNTIVO",
    "v51_9,PERDER (lose) (*),FUTURO @CONJUNTIVO",
    "v51_i,PERDER (lose) (*),IMPERATIVO RESTRITO",
    "v51_n,PERDER (lose) (*),INFINITIVO PESSOAL",
    "v51_p,PERDER (lose) (*),FORMAS NOMINAIS",
    "v52_1,VALER (be_worth) (*),PRETÉRITO PERFEITO",
    "v52_2,VALER (be_worth) (*),PRESENTE",
    "v52_3,VALER (be_worth) (*),FUTURO",
    "v52_4,VALER (be_worth) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v52_5,VALER (be_worth) (*),PRETÉRITO IMPERFEITO",
    "v52_6,VALER (be_worth) (*),@CONDICIONAL",
    "v52_7,VALER (be_worth) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v52_8,VALER (be_worth) (*),PRESENTE @CONJUNTIVO",
    "v52_9,VALER (be_worth) (*),FUTURO @CONJUNTIVO",
    "v52_i,VALER (be_worth) (*),IMPERATIVO RESTRITO",
    "v52_n,VALER (be_worth) (*),INFINITIVO PESSOAL",
    "v52_p,VALER (be_worth) (*),FORMAS NOMINAIS",
    "v53_1,MEDIR (measure) (*),PRETÉRITO PERFEITO",
    "v53_2,MEDIR (measure) (*),PRESENTE",
    "v53_3,MEDIR (measure) (*),FUTURO",
    "v53_4,MEDIR (measure) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v53_5,MEDIR (measure) (*),PRETÉRITO IMPERFEITO",
    "v53_6,MEDIR (measure) (*),@CONDICIONAL",
    "v53_7,MEDIR (measure) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v53_8,MEDIR (measure) (*),PRESENTE @CONJUNTIVO",
    "v53_9,MEDIR (measure) (*),FUTURO @CONJUNTIVO",
    "v53_i,MEDIR (measure) (*),IMPERATIVO RESTRITO",
    "v53_n,MEDIR (measure) (*),INFINITIVO PESSOAL",
    "v53_p,MEDIR (measure) (*),FORMAS NOMINAIS",
    "v54_1,OUVIR (hear) (*),PRETÉRITO PERFEITO",
    "v54_2,OUVIR (hear) (*),PRESENTE",
    "v54_3,OUVIR (hear) (*),FUTURO",
    "v54_4,OUVIR (hear) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v54_5,OUVIR (hear) (*),PRETÉRITO IMPERFEITO",
    "v54_6,OUVIR (hear) (*),@CONDICIONAL",
    "v54_7,OUVIR (hear) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v54_8,OUVIR (hear) (*),PRESENTE @CONJUNTIVO",
    "v54_9,OUVIR (hear) (*),FUTURO @CONJUNTIVO",
    "v54_i,OUVIR (hear) (*),IMPERATIVO RESTRITO",
    "v54_n,OUVIR (hear) (*),INFINITIVO PESSOAL",
    "v54_p,OUVIR (hear) (*),FORMAS NOMINAIS",
    "v55_1,PARIR (give_birth) (*),PRETÉRITO PERFEITO",
    "v55_2,PARIR (give_birth) (*),PRESENTE",
    "v55_3,PARIR (give_birth) (*),FUTURO",
    "v55_4,PARIR (give_birth) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v55_5,PARIR (give_birth) (*),PRETÉRITO IMPERFEITO",
    "v55_6,PARIR (give_birth) (*),@CONDICIONAL",
    "v55_7,PARIR (give_birth) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v55_8,PARIR (give_birth) (*),PRESENTE @CONJUNTIVO",
    "v55_9,PARIR (give_birth) (*),FUTURO @CONJUNTIVO",
    "v55_i,PARIR (give_birth) (*),IMPERATIVO RESTRITO",
    "v55_n,PARIR (give_birth) (*),INFINITIVO PESSOAL",
    "v55_p,PARIR (give_birth) (*),FORMAS NOMINAIS",
    "v56_1,REQUERER (request) (*),PRETÉRITO PERFEITO",
    "v56_2,REQUERER (request) (*),PRESENTE",
    "v56_3,REQUERER (request) (*),FUTURO",
    "v56_4,REQUERER (request) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v56_5,REQUERER (request) (*),PRETÉRITO IMPERFEITO",
    "v56_6,REQUERER (request) (*),@CONDICIONAL",
    "v56_7,REQUERER (request) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v56_8,REQUERER (request) (*),PRESENTE @CONJUNTIVO",
    "v56_9,REQUERER (request) (*),FUTURO @CONJUNTIVO",
    "v56_i,REQUERER (request) (*),IMPERATIVO RESTRITO",
    "v56_n,REQUERER (request) (*),INFINITIVO PESSOAL",
    "v56_p,REQUERER (request) (*),FORMAS NOMINAIS",
    "v57_1,LER (read) (*),PRETÉRITO PERFEITO",
    "v57_2,LER (read) (*),PRESENTE",
    "v57_3,LER (read) (*),FUTURO",
    "v57_4,LER (read) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v57_5,LER (read) (*),PRETÉRITO IMPERFEITO",
    "v57_6,LER (read) (*),@CONDICIONAL",
    "v57_7,LER (read) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v57_8,LER (read) (*),PRESENTE @CONJUNTIVO",
    "v57_9,LER (read) (*),FUTURO @CONJUNTIVO",
    "v57_i,LER (read) (*),IMPERATIVO RESTRITO",
    "v57_n,LER (read) (*),INFINITIVO PESSOAL",
    "v57_p,LER (read) (*),FORMAS NOMINAIS",
    "v58_1,PROVER (provide) (*),PRETÉRITO PERFEITO",
    "v58_2,PROVER (provide) (*),PRESENTE",
    "v58_3,PROVER (provide) (*),FUTURO",
    "v58_4,PROVER (provide) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v58_5,PROVER (provide) (*),PRETÉRITO IMPERFEITO",
    "v58_6,PROVER (provide) (*),@CONDICIONAL",
    "v58_7,PROVER (provide) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v58_8,PROVER (provide) (*),PRESENTE @CONJUNTIVO",
    "v58_9,PROVER (provide) (*),FUTURO @CONJUNTIVO",
    "v58_i,PROVER (provide) (*),IMPERATIVO RESTRITO",
    "v58_n,PROVER (provide) (*),INFINITIVO PESSOAL",
    "v58_p,PROVER (provide) (*),FORMAS NOMINAIS",
    "v59_1,RIR (laugh) (*),PRETÉRITO PERFEITO",
    "v59_2,RIR (laugh) (*),PRESENTE",
    "v59_3,RIR (laugh) (*),FUTURO",
    "v59_4,RIR (laugh) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v59_5,RIR (laugh) (*),PRETÉRITO IMPERFEITO",
    "v59_6,RIR (laugh) (*),@CONDICIONAL",
    "v59_7,RIR (laugh) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v59_8,RIR (laugh) (*),PRESENTE @CONJUNTIVO",
    "v59_9,RIR (laugh) (*),FUTURO @CONJUNTIVO",
    "v59_i,RIR (laugh) (*),IMPERATIVO RESTRITO",
    "v59_n,RIR (laugh) (*),INFINITIVO PESSOAL",
    "v59_p,RIR (laugh) (*),FORMAS NOMINAIS",
];

export const verbos6 = [
    "v61_1,COMPRAZER (please) (*),PRETÉRITO PERFEITO",
    "v61_2,COMPRAZER (please) (*),PRESENTE",
    "v61_3,COMPRAZER (please) (*),FUTURO",
    "v61_4,COMPRAZER (please) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v61_5,COMPRAZER (please) (*),PRETÉRITO IMPERFEITO",
    "v61_6,COMPRAZER (please) (*),@CONDICIONAL",
    "v61_7,COMPRAZER (please) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v61_8,COMPRAZER (please) (*),PRESENTE @CONJUNTIVO",
    "v61_9,COMPRAZER (please) (*),FUTURO @CONJUNTIVO",
    "v61_i,COMPRAZER (please) (*),IMPERATIVO RESTRITO",
    "v61_n,COMPRAZER (please) (*),INFINITIVO PESSOAL",
    "v61_p,COMPRAZER (please) (*),FORMAS NOMINAIS",
    "v62_1,CABER (fit) (*),PRETÉRITO PERFEITO",
    "v62_2,CABER (fit) (*),PRESENTE",
    "v62_3,CABER (fit) (*),FUTURO",
    "v62_4,CABER (fit) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v62_5,CABER (fit) (*),PRETÉRITO IMPERFEITO",
    "v62_6,CABER (fit) (*),@CONDICIONAL",
    "v62_7,CABER (fit) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v62_8,CABER (fit) (*),PRESENTE @CONJUNTIVO",
    "v62_9,CABER (fit) (*),FUTURO @CONJUNTIVO",
    "v62_i,CABER (fit) (*),IMPERATIVO RESTRITO",
    "v62_n,CABER (fit) (*),INFINITIVO PESSOAL",
    "v62_p,CABER (fit) (*),FORMAS NOMINAIS",
    "v63_1,DAR (give) (*),PRETÉRITO PERFEITO",
    "v63_2,DAR (give) (*),PRESENTE",
    "v63_3,DAR (give) (*),FUTURO",
    "v63_4,DAR (give) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v63_5,DAR (give) (*),PRETÉRITO IMPERFEITO",
    "v63_6,DAR (give) (*),@CONDICIONAL",
    "v63_7,DAR (give) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v63_8,DAR (give) (*),PRESENTE @CONJUNTIVO",
    "v63_9,DAR (give) (*),FUTURO @CONJUNTIVO",
    "v63_i,DAR (give) (*),IMPERATIVO RESTRITO",
    "v63_n,DAR (give) (*),INFINITIVO PESSOAL",
    "v63_p,DAR (give) (*),FORMAS NOMINAIS",
    "v64_1,PODER (be_able_to) (*),PRETÉRITO PERFEITO",
    "v64_2,PODER (be_able_to) (*),PRESENTE",
    "v64_3,PODER (be_able_to) (*),FUTURO",
    "v64_4,PODER (be_able_to) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v64_5,PODER (be_able_to) (*),PRETÉRITO IMPERFEITO",
    "v64_6,PODER (be_able_to) (*),@CONDICIONAL",
    "v64_7,PODER (be_able_to) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v64_8,PODER (be_able_to) (*),PRESENTE @CONJUNTIVO",
    "v64_9,PODER (be_able_to) (*),FUTURO @CONJUNTIVO",
    "v64_i,PODER (be_able_to) (*),IMPERATIVO RESTRITO",
    "v64_n,PODER (be_able_to) (*),INFINITIVO PESSOAL",
    "v64_p,PODER (be_able_to) (*),FORMAS NOMINAIS",
    "v65_1,VER (see) (*),PRETÉRITO PERFEITO",
    "v65_2,VER (see) (*),PRESENTE",
    "v65_3,VER (see) (*),FUTURO",
    "v65_4,VER (see) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v65_5,VER (see) (*),PRETÉRITO IMPERFEITO",
    "v65_6,VER (see) (*),@CONDICIONAL",
    "v65_7,VER (see) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v65_8,VER (see) (*),PRESENTE @CONJUNTIVO",
    "v65_9,VER (see) (*),FUTURO @CONJUNTIVO",
    "v65_i,VER (see) (*),IMPERATIVO RESTRITO",
    "v65_n,VER (see) (*),INFINITIVO PESSOAL",
    "v65_p,VER (see) (*),FORMAS NOMINAIS",
    "v66_1,DIZER (say) (*),PRETÉRITO PERFEITO",
    "v66_2,DIZER (say) (*),PRESENTE",
    "v66_3,DIZER (say) (*),FUTURO",
    "v66_4,DIZER (say) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v66_5,DIZER (say) (*),PRETÉRITO IMPERFEITO",
    "v66_6,DIZER (say) (*),@CONDICIONAL",
    "v66_7,DIZER (say) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v66_8,DIZER (say) (*),PRESENTE @CONJUNTIVO",
    "v66_9,DIZER (say) (*),FUTURO @CONJUNTIVO",
    "v66_i,DIZER (say) (*),IMPERATIVO RESTRITO",
    "v66_n,DIZER (say) (*),INFINITIVO PESSOAL",
    "v66_p,DIZER (say) (*),FORMAS NOMINAIS",
    "v67_1,FAZER (do) (*),PRETÉRITO PERFEITO",
    "v67_2,FAZER (do) (*),PRESENTE",
    "v67_3,FAZER (do) (*),FUTURO",
    "v67_4,FAZER (do) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v67_5,FAZER (do) (*),PRETÉRITO IMPERFEITO",
    "v67_6,FAZER (do) (*),@CONDICIONAL",
    "v67_7,FAZER (do) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v67_8,FAZER (do) (*),PRESENTE @CONJUNTIVO",
    "v67_9,FAZER (do) (*),FUTURO @CONJUNTIVO",
    "v67_i,FAZER (do) (*),IMPERATIVO RESTRITO",
    "v67_n,FAZER (do) (*),INFINITIVO PESSOAL",
    "v67_p,FAZER (do) (*),FORMAS NOMINAIS",
    "v68_1,TRAZER (bring) (*),PRETÉRITO PERFEITO",
    "v68_2,TRAZER (bring) (*),PRESENTE",
    "v68_3,TRAZER (bring) (*),FUTURO",
    "v68_4,TRAZER (bring) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v68_5,TRAZER (bring) (*),PRETÉRITO IMPERFEITO",
    "v68_6,TRAZER (bring) (*),@CONDICIONAL",
    "v68_7,TRAZER (bring) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v68_8,TRAZER (bring) (*),PRESENTE @CONJUNTIVO",
    "v68_9,TRAZER (bring) (*),FUTURO @CONJUNTIVO",
    "v68_i,TRAZER (bring) (*),IMPERATIVO RESTRITO",
    "v68_n,TRAZER (bring) (*),INFINITIVO PESSOAL",
    "v68_p,TRAZER (bring) (*),FORMAS NOMINAIS",
    "v69_1,TER (have) (*),PRETÉRITO PERFEITO",
    "v69_2,TER (have) (*),PRESENTE",
    "v69_3,TER (have) (*),FUTURO",
    "v69_4,TER (have) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v69_5,TER (have) (*),PRETÉRITO IMPERFEITO",
    "v69_6,TER (have) (*),@CONDICIONAL",
    "v69_7,TER (have) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v69_8,TER (have) (*),PRESENTE @CONJUNTIVO",
    "v69_9,TER (have) (*),FUTURO @CONJUNTIVO",
    "v69_i,TER (have) (*),IMPERATIVO RESTRITO",
    "v69_n,TER (have) (*),INFINITIVO PESSOAL",
    "v69_p,TER (have) (*),FORMAS NOMINAIS",
    "v6a_1,VIR (come) (*),PRETÉRITO PERFEITO",
    "v6a_2,VIR (come) (*),PRESENTE",
    "v6a_3,VIR (come) (*),FUTURO",
    "v6a_4,VIR (come) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v6a_5,VIR (come) (*),PRETÉRITO IMPERFEITO",
    "v6a_6,VIR (come) (*),@CONDICIONAL",
    "v6a_7,VIR (come) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v6a_8,VIR (come) (*),PRESENTE @CONJUNTIVO",
    "v6a_9,VIR (come) (*),FUTURO @CONJUNTIVO",
    "v6a_i,VIR (come) (*),IMPERATIVO RESTRITO",
    "v6a_n,VIR (come) (*),INFINITIVO PESSOAL",
    "v6a_p,VIR (come) (*),FORMAS NOMINAIS",
    "v6b_1,PÔR (place) (-POR),PRETÉRITO PERFEITO",
    "v6b_2,PÔR (place) (-POR),PRESENTE",
    "v6b_3,PÔR (place) (-POR),FUTURO",
    "v6b_4,PÔR (place) (-POR),PRETÉRITO MAIS-QUE-PERFEITO",
    "v6b_5,PÔR (place) (-POR),PRETÉRITO IMPERFEITO",
    "v6b_6,PÔR (place) (-POR),@CONDICIONAL",
    "v6b_7,PÔR (place) (-POR),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v6b_8,PÔR (place) (-POR),PRESENTE @CONJUNTIVO",
    "v6b_9,PÔR (place) (-POR),FUTURO @CONJUNTIVO",
    "v6b_i,PÔR (place) (-POR),IMPERATIVO RESTRITO",
    "v6b_n,PÔR (place) (-POR),INFINITIVO PESSOAL",
    "v6b_p,PÔR (place) (-POR),FORMAS NOMINAIS",
];

export const verbos7 = [
    "v71_1,QUERER (want) (*),PRETÉRITO PERFEITO",
    "v71_2,QUERER (want) (*),PRESENTE",
    "v71_3,QUERER (want) (*),FUTURO",
    "v71_4,QUERER (want) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v71_5,QUERER (want) (*),PRETÉRITO IMPERFEITO",
    "v71_6,QUERER (want) (*),@CONDICIONAL",
    "v71_7,QUERER (want) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v71_8,QUERER (want) (*),PRESENTE @CONJUNTIVO",
    "v71_9,QUERER (want) (*),FUTURO @CONJUNTIVO",
    "v71_i,QUERER (want) (*),IMPERATIVO RESTRITO",
    "v71_n,QUERER (want) (*),INFINITIVO PESSOAL",
    "v71_p,QUERER (want) (*),FORMAS NOMINAIS",
    "v72_1,ESTAR (be_[occasional]) (*),PRETÉRITO PERFEITO",
    "v72_2,ESTAR (be_[occasional]) (*),PRESENTE",
    "v72_3,ESTAR (be_[occasional]) (*),FUTURO",
    "v72_4,ESTAR (be_[occasional]) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v72_5,ESTAR (be_[occasional]) (*),PRETÉRITO IMPERFEITO",
    "v72_6,ESTAR (be_[occasional]) (*),@CONDICIONAL",
    "v72_7,ESTAR (be_[occasional]) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v72_8,ESTAR (be_[occasional]) (*),PRESENTE @CONJUNTIVO",
    "v72_9,ESTAR (be_[occasional]) (*),FUTURO @CONJUNTIVO",
    "v72_i,ESTAR (be_[occasional]) (*),IMPERATIVO RESTRITO",
    "v72_n,ESTAR (be_[occasional]) (*),INFINITIVO PESSOAL",
    "v72_p,ESTAR (be_[occasional]) (*),FORMAS NOMINAIS",
    "v73_1,HAVER (there_to_be) (*),PRETÉRITO PERFEITO",
    "v73_2,HAVER (there_to_be) (*),PRESENTE",
    "v73_3,HAVER (there_to_be) (*),FUTURO",
    "v73_4,HAVER (there_to_be) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v73_5,HAVER (there_to_be) (*),PRETÉRITO IMPERFEITO",
    "v73_6,HAVER (there_to_be) (*),@CONDICIONAL",
    "v73_7,HAVER (there_to_be) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v73_8,HAVER (there_to_be) (*),PRESENTE @CONJUNTIVO",
    "v73_9,HAVER (there_to_be) (*),FUTURO @CONJUNTIVO",
    "v73_i,HAVER (there_to_be) (*),IMPERATIVO RESTRITO",
    "v73_n,HAVER (there_to_be) (*),INFINITIVO PESSOAL",
    "v73_p,HAVER (there_to_be) (*),FORMAS NOMINAIS",
    "v74_1,SABER (know) (*),PRETÉRITO PERFEITO",
    "v74_2,SABER (know) (*),PRESENTE",
    "v74_3,SABER (know) (*),FUTURO",
    "v74_4,SABER (know) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v74_5,SABER (know) (*),PRETÉRITO IMPERFEITO",
    "v74_6,SABER (know) (*),@CONDICIONAL",
    "v74_7,SABER (know) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v74_8,SABER (know) (*),PRESENTE @CONJUNTIVO",
    "v74_9,SABER (know) (*),FUTURO @CONJUNTIVO",
    "v74_i,SABER (know) (*),IMPERATIVO RESTRITO",
    "v74_n,SABER (know) (*),INFINITIVO PESSOAL",
    "v74_p,SABER (know) (*),FORMAS NOMINAIS",
    "v75_1,IR (go) (*),PRETÉRITO PERFEITO",
    "v75_2,IR (go) (*),PRESENTE",
    "v75_3,IR (go) (*),FUTURO",
    "v75_4,IR (go) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v75_5,IR (go) (*),PRETÉRITO IMPERFEITO",
    "v75_6,IR (go) (*),@CONDICIONAL",
    "v75_7,IR (go) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v75_8,IR (go) (*),PRESENTE @CONJUNTIVO",
    "v75_9,IR (go) (*),FUTURO @CONJUNTIVO",
    "v75_i,IR (go) (*),IMPERATIVO RESTRITO",
    "v75_n,IR (go) (*),INFINITIVO PESSOAL",
    "v75_p,IR (go) (*),FORMAS NOMINAIS",
    "v76_1,SER (be_[essence]) (*),PRETÉRITO PERFEITO",
    "v76_2,SER (be_[essence]) (*),PRESENTE",
    "v76_3,SER (be_[essence]) (*),FUTURO",
    "v76_4,SER (be_[essence]) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "v76_5,SER (be_[essence]) (*),PRETÉRITO IMPERFEITO",
    "v76_6,SER (be_[essence]) (*),@CONDICIONAL",
    "v76_7,SER (be_[essence]) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "v76_8,SER (be_[essence]) (*),PRESENTE @CONJUNTIVO",
    "v76_9,SER (be_[essence]) (*),FUTURO @CONJUNTIVO",
    "v76_i,SER (be_[essence]) (*),IMPERATIVO RESTRITO",
    "v76_n,SER (be_[essence]) (*),INFINITIVO PESSOAL",
    "v76_p,SER (be_[essence]) (*),FORMAS NOMINAIS",
];

export const tempos1 = [
    "1_v11,CHAMAR (call) (-AR),PRETÉRITO PERFEITO",
    "1_v12,VENDER (sell) (-ER),PRETÉRITO PERFEITO",
    "1_v13,PARTIR (leave) (-IR),PRETÉRITO PERFEITO",
    "2_v11,CHAMAR (call) (-AR),PRESENTE",
    "2_v12,VENDER (sell) (-ER),PRESENTE",
    "2_v13,PARTIR (leave) (-IR),PRESENTE",
    "3_v11,CHAMAR (call) (-AR),FUTURO",
    "3_v12,VENDER (sell) (-ER),FUTURO",
    "3_v13,PARTIR (leave) (-IR),FUTURO",
    "4_v11,CHAMAR (call) (-AR),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v12,VENDER (sell) (-ER),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v13,PARTIR (leave) (-IR),PRETÉRITO MAIS-QUE-PERFEITO",
    "5_v11,CHAMAR (call) (-AR),PRETÉRITO IMPERFEITO",
    "5_v12,VENDER (sell) (-ER),PRETÉRITO IMPERFEITO",
    "5_v13,PARTIR (leave) (-IR),PRETÉRITO IMPERFEITO",
    "6_v11,CHAMAR (call) (-AR),@CONDICIONAL",
    "6_v12,VENDER (sell) (-ER),@CONDICIONAL",
    "6_v13,PARTIR (leave) (-IR),@CONDICIONAL",
    "7_v11,CHAMAR (call) (-AR),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v12,VENDER (sell) (-ER),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v13,PARTIR (leave) (-IR),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "8_v11,CHAMAR (call) (-AR),PRESENTE @CONJUNTIVO",
    "8_v12,VENDER (sell) (-ER),PRESENTE @CONJUNTIVO",
    "8_v13,PARTIR (leave) (-IR),PRESENTE @CONJUNTIVO",
    "9_v11,CHAMAR (call) (-AR),FUTURO @CONJUNTIVO",
    "9_v12,VENDER (sell) (-ER),FUTURO @CONJUNTIVO",
    "9_v13,PARTIR (leave) (-IR),FUTURO @CONJUNTIVO",
    "i_v11,CHAMAR (call) (-AR),IMPERATIVO RESTRITO",
    "i_v12,VENDER (sell) (-ER),IMPERATIVO RESTRITO",
    "i_v13,PARTIR (leave) (-IR),IMPERATIVO RESTRITO",
    "n_v11,CHAMAR (call) (-AR),INFINITIVO PESSOAL",
    "n_v12,VENDER (sell) (-ER),INFINITIVO PESSOAL",
    "n_v13,PARTIR (leave) (-IR),INFINITIVO PESSOAL",
    "p_v11,CHAMAR (call) (-AR),FORMAS NOMINAIS",
    "p_v12,VENDER (sell) (-ER),FORMAS NOMINAIS",
    "p_v13,PARTIR (leave) (-IR),FORMAS NOMINAIS",
];

export const tempos2 = [
    "1_v21,PASSEAR (stroll) (-EAR),PRETÉRITO PERFEITO",
    "1_v22,MOER (grind) (-OER),PRETÉRITO PERFEITO",
    "1_v23,ATRAIR (attract) (-AIR),PRETÉRITO PERFEITO",
    "1_v24,ATRIBUIR (assign) (-UIR),PRETÉRITO PERFEITO",
    "1_v25,ARGUIR (argue) (-GUIR),PRETÉRITO PERFEITO",
    "2_v21,PASSEAR (stroll) (-EAR),PRESENTE",
    "2_v22,MOER (grind) (-OER),PRESENTE",
    "2_v23,ATRAIR (attract) (-AIR),PRESENTE",
    "2_v24,ATRIBUIR (assign) (-UIR),PRESENTE",
    "2_v25,ARGUIR (argue) (-GUIR),PRESENTE",
    "3_v21,PASSEAR (stroll) (-EAR),FUTURO",
    "3_v22,MOER (grind) (-OER),FUTURO",
    "3_v23,ATRAIR (attract) (-AIR),FUTURO",
    "3_v24,ATRIBUIR (assign) (-UIR),FUTURO",
    "3_v25,ARGUIR (argue) (-GUIR),FUTURO",
    "4_v21,PASSEAR (stroll) (-EAR),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v22,MOER (grind) (-OER),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v23,ATRAIR (attract) (-AIR),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v24,ATRIBUIR (assign) (-UIR),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v25,ARGUIR (argue) (-GUIR),PRETÉRITO MAIS-QUE-PERFEITO",
    "5_v21,PASSEAR (stroll) (-EAR),PRETÉRITO IMPERFEITO",
    "5_v22,MOER (grind) (-OER),PRETÉRITO IMPERFEITO",
    "5_v23,ATRAIR (attract) (-AIR),PRETÉRITO IMPERFEITO",
    "5_v24,ATRIBUIR (assign) (-UIR),PRETÉRITO IMPERFEITO",
    "5_v25,ARGUIR (argue) (-GUIR),PRETÉRITO IMPERFEITO",
    "6_v21,PASSEAR (stroll) (-EAR),@CONDICIONAL",
    "6_v22,MOER (grind) (-OER),@CONDICIONAL",
    "6_v23,ATRAIR (attract) (-AIR),@CONDICIONAL",
    "6_v24,ATRIBUIR (assign) (-UIR),@CONDICIONAL",
    "6_v25,ARGUIR (argue) (-GUIR),@CONDICIONAL",
    "7_v21,PASSEAR (stroll) (-EAR),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v22,MOER (grind) (-OER),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v23,ATRAIR (attract) (-AIR),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v24,ATRIBUIR (assign) (-UIR),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v25,ARGUIR (argue) (-GUIR),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "8_v21,PASSEAR (stroll) (-EAR),PRESENTE @CONJUNTIVO",
    "8_v22,MOER (grind) (-OER),PRESENTE @CONJUNTIVO",
    "8_v23,ATRAIR (attract) (-AIR),PRESENTE @CONJUNTIVO",
    "8_v24,ATRIBUIR (assign) (-UIR),PRESENTE @CONJUNTIVO",
    "8_v25,ARGUIR (argue) (-GUIR),PRESENTE @CONJUNTIVO",
    "9_v21,PASSEAR (stroll) (-EAR),FUTURO @CONJUNTIVO",
    "9_v22,MOER (grind) (-OER),FUTURO @CONJUNTIVO",
    "9_v23,ATRAIR (attract) (-AIR),FUTURO @CONJUNTIVO",
    "9_v24,ATRIBUIR (assign) (-UIR),FUTURO @CONJUNTIVO",
    "9_v25,ARGUIR (argue) (-GUIR),FUTURO @CONJUNTIVO",
    "i_v21,PASSEAR (stroll) (-EAR),IMPERATIVO RESTRITO",
    "i_v22,MOER (grind) (-OER),IMPERATIVO RESTRITO",
    "i_v23,ATRAIR (attract) (-AIR),IMPERATIVO RESTRITO",
    "i_v24,ATRIBUIR (assign) (-UIR),IMPERATIVO RESTRITO",
    "i_v25,ARGUIR (argue) (-GUIR),IMPERATIVO RESTRITO",
    "n_v21,PASSEAR (stroll) (-EAR),INFINITIVO PESSOAL",
    "n_v22,MOER (grind) (-OER),INFINITIVO PESSOAL",
    "n_v23,ATRAIR (attract) (-AIR),INFINITIVO PESSOAL",
    "n_v24,ATRIBUIR (assign) (-UIR),INFINITIVO PESSOAL",
    "n_v25,ARGUIR (argue) (-GUIR),INFINITIVO PESSOAL",
    "p_v21,PASSEAR (stroll) (-EAR),FORMAS NOMINAIS",
    "p_v22,MOER (grind) (-OER),FORMAS NOMINAIS",
    "p_v23,ATRAIR (attract) (-AIR),FORMAS NOMINAIS",
    "p_v24,ATRIBUIR (assign) (-UIR),FORMAS NOMINAIS",
    "p_v25,ARGUIR (argue) (-GUIR),FORMAS NOMINAIS",
];

export const tempos3 = [
    "1_v30,DESAGUAR (flow) (@),PRETÉRITO PERFEITO",
    "1_v31,ADEQUAR (adapt) (@),PRETÉRITO PERFEITO",
    "1_v32,DELINQUIR (commit_an_ofense) (@),PRETÉRITO PERFEITO",
    "1_v33,COMERCIAR (trade) (@),PRETÉRITO PERFEITO",
    "1_v34,CONSTRUIR (build) (@),PRETÉRITO PERFEITO",
    "1_v35,EMERGIR (emerge) (@),PRETÉRITO PERFEITO",
    "1_v36,EXPLODIR (explode) (@),PRETÉRITO PERFEITO",
    "2_v30,DESAGUAR (flow) (@),PRESENTE",
    "2_v31,ADEQUAR (adapt) (@),PRESENTE",
    "2_v32,DELINQUIR (commit_an_ofense) (@),PRESENTE",
    "2_v33,COMERCIAR (trade) (@),PRESENTE",
    "2_v34,CONSTRUIR (build) (@),PRESENTE",
    "2_v35,EMERGIR (emerge) (@),PRESENTE",
    "2_v36,EXPLODIR (explode) (@),PRESENTE",
    "3_v30,DESAGUAR (flow) (@),FUTURO",
    "3_v31,ADEQUAR (adapt) (@),FUTURO",
    "3_v32,DELINQUIR (commit_an_ofense) (@),FUTURO",
    "3_v33,COMERCIAR (trade) (@),FUTURO",
    "3_v34,CONSTRUIR (build) (@),FUTURO",
    "3_v35,EMERGIR (emerge) (@),FUTURO",
    "3_v36,EXPLODIR (explode) (@),FUTURO",
    "4_v30,DESAGUAR (flow) (@),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v31,ADEQUAR (adapt) (@),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v32,DELINQUIR (commit_an_ofense) (@),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v33,COMERCIAR (trade) (@),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v34,CONSTRUIR (build) (@),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v35,EMERGIR (emerge) (@),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v36,EXPLODIR (explode) (@),PRETÉRITO MAIS-QUE-PERFEITO",
    "5_v30,DESAGUAR (flow) (@),PRETÉRITO IMPERFEITO",
    "5_v31,ADEQUAR (adapt) (@),PRETÉRITO IMPERFEITO",
    "5_v32,DELINQUIR (commit_an_ofense) (@),PRETÉRITO IMPERFEITO",
    "5_v33,COMERCIAR (trade) (@),PRETÉRITO IMPERFEITO",
    "5_v34,CONSTRUIR (build) (@),PRETÉRITO IMPERFEITO",
    "5_v35,EMERGIR (emerge) (@),PRETÉRITO IMPERFEITO",
    "5_v36,EXPLODIR (explode) (@),PRETÉRITO IMPERFEITO",
    "6_v30,DESAGUAR (flow) (@),@CONDICIONAL",
    "6_v31,ADEQUAR (adapt) (@),@CONDICIONAL",
    "6_v32,DELINQUIR (commit_an_ofense) (@),@CONDICIONAL",
    "6_v33,COMERCIAR (trade) (@),@CONDICIONAL",
    "6_v34,CONSTRUIR (build) (@),@CONDICIONAL",
    "6_v35,EMERGIR (emerge) (@),@CONDICIONAL",
    "6_v36,EXPLODIR (explode) (@),@CONDICIONAL",
    "7_v30,DESAGUAR (flow) (@),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v31,ADEQUAR (adapt) (@),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v32,DELINQUIR (commit_an_ofense) (@),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v33,COMERCIAR (trade) (@),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v34,CONSTRUIR (build) (@),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v35,EMERGIR (emerge) (@),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v36,EXPLODIR (explode) (@),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "8_v30,DESAGUAR (flow) (@),PRESENTE @CONJUNTIVO",
    "8_v31,ADEQUAR (adapt) (@),PRESENTE @CONJUNTIVO",
    "8_v32,DELINQUIR (commit_an_ofense) (@),PRESENTE @CONJUNTIVO",
    "8_v33,COMERCIAR (trade) (@),PRESENTE @CONJUNTIVO",
    "8_v34,CONSTRUIR (build) (@),PRESENTE @CONJUNTIVO",
    "8_v35,EMERGIR (emerge) (@),PRESENTE @CONJUNTIVO",
    "8_v36,EXPLODIR (explode) (@),PRESENTE @CONJUNTIVO",
    "9_v30,DESAGUAR (flow) (@),FUTURO @CONJUNTIVO",
    "9_v31,ADEQUAR (adapt) (@),FUTURO @CONJUNTIVO",
    "9_v32,DELINQUIR (commit_an_ofense) (@),FUTURO @CONJUNTIVO",
    "9_v33,COMERCIAR (trade) (@),FUTURO @CONJUNTIVO",
    "9_v34,CONSTRUIR (build) (@),FUTURO @CONJUNTIVO",
    "9_v35,EMERGIR (emerge) (@),FUTURO @CONJUNTIVO",
    "9_v36,EXPLODIR (explode) (@),FUTURO @CONJUNTIVO",
    "i_v30,DESAGUAR (flow) (@),IMPERATIVO RESTRITO",
    "i_v31,ADEQUAR (adapt) (@),IMPERATIVO RESTRITO",
    "i_v32,DELINQUIR (commit_an_ofense) (@),IMPERATIVO RESTRITO",
    "i_v33,COMERCIAR (trade) (@),IMPERATIVO RESTRITO",
    "i_v34,CONSTRUIR (build) (@),IMPERATIVO RESTRITO",
    "i_v35,EMERGIR (emerge) (@),IMPERATIVO RESTRITO",
    "i_v36,EXPLODIR (explode) (@),IMPERATIVO RESTRITO",
    "n_v30,DESAGUAR (flow) (@),INFINITIVO PESSOAL",
    "n_v31,ADEQUAR (adapt) (@),INFINITIVO PESSOAL",
    "n_v32,DELINQUIR (commit_an_ofense) (@),INFINITIVO PESSOAL",
    "n_v33,COMERCIAR (trade) (@),INFINITIVO PESSOAL",
    "n_v34,CONSTRUIR (build) (@),INFINITIVO PESSOAL",
    "n_v35,EMERGIR (emerge) (@),INFINITIVO PESSOAL",
    "n_v36,EXPLODIR (explode) (@),INFINITIVO PESSOAL",
    "p_v30,DESAGUAR (flow) (@),FORMAS NOMINAIS",
    "p_v31,ADEQUAR (adapt) (@),FORMAS NOMINAIS",
    "p_v32,DELINQUIR (commit_an_ofense) (@),FORMAS NOMINAIS",
    "p_v33,COMERCIAR (trade) (@),FORMAS NOMINAIS",
    "p_v34,CONSTRUIR (build) (@),FORMAS NOMINAIS",
    "p_v35,EMERGIR (emerge) (@),FORMAS NOMINAIS",
    "p_v36,EXPLODIR (explode) (@),FORMAS NOMINAIS",
];

export const tempos4 = [
    "1_v41,ANSIAR (long_for) (-IAR¹),PRETÉRITO PERFEITO",
    "1_v42,SENTIR (feel) (-E-IR¹),PRETÉRITO PERFEITO",
    "1_v43,AGREDIR (hit) (-E-IR²),PRETÉRITO PERFEITO",
    "1_v44,FRIGIR (fry) (-I-IR¹),PRETÉRITO PERFEITO",
    "1_v45,DORMIR (sleep) (-O-IR¹),PRETÉRITO PERFEITO",
    "1_v46,POLIR (polish) (-O-IR²),PRETÉRITO PERFEITO",
    "1_v47,SACUDIR (shake) (-U-IR¹),PRETÉRITO PERFEITO",
    "1_v48,CERZIR (darn) (-E-IR¹²),PRETÉRITO PERFEITO",
    "2_v41,ANSIAR (long_for) (-IAR¹),PRESENTE",
    "2_v42,SENTIR (feel) (-E-IR¹),PRESENTE",
    "2_v43,AGREDIR (hit) (-E-IR²),PRESENTE",
    "2_v44,FRIGIR (fry) (-I-IR¹),PRESENTE",
    "2_v45,DORMIR (sleep) (-O-IR¹),PRESENTE",
    "2_v46,POLIR (polish) (-O-IR²),PRESENTE",
    "2_v47,SACUDIR (shake) (-U-IR¹),PRESENTE",
    "2_v48,CERZIR (darn) (-E-IR¹²),PRESENTE",
    "3_v41,ANSIAR (long_for) (-IAR¹),FUTURO",
    "3_v42,SENTIR (feel) (-E-IR¹),FUTURO",
    "3_v43,AGREDIR (hit) (-E-IR²),FUTURO",
    "3_v44,FRIGIR (fry) (-I-IR¹),FUTURO",
    "3_v45,DORMIR (sleep) (-O-IR¹),FUTURO",
    "3_v46,POLIR (polish) (-O-IR²),FUTURO",
    "3_v47,SACUDIR (shake) (-U-IR¹),FUTURO",
    "3_v48,CERZIR (darn) (-E-IR¹²),FUTURO",
    "4_v41,ANSIAR (long_for) (-IAR¹),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v42,SENTIR (feel) (-E-IR¹),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v43,AGREDIR (hit) (-E-IR²),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v44,FRIGIR (fry) (-I-IR¹),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v45,DORMIR (sleep) (-O-IR¹),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v46,POLIR (polish) (-O-IR²),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v47,SACUDIR (shake) (-U-IR¹),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v48,CERZIR (darn) (-E-IR¹²),PRETÉRITO MAIS-QUE-PERFEITO",
    "5_v41,ANSIAR (long_for) (-IAR¹),PRETÉRITO IMPERFEITO",
    "5_v42,SENTIR (feel) (-E-IR¹),PRETÉRITO IMPERFEITO",
    "5_v43,AGREDIR (hit) (-E-IR²),PRETÉRITO IMPERFEITO",
    "5_v44,FRIGIR (fry) (-I-IR¹),PRETÉRITO IMPERFEITO",
    "5_v45,DORMIR (sleep) (-O-IR¹),PRETÉRITO IMPERFEITO",
    "5_v46,POLIR (polish) (-O-IR²),PRETÉRITO IMPERFEITO",
    "5_v47,SACUDIR (shake) (-U-IR¹),PRETÉRITO IMPERFEITO",
    "5_v48,CERZIR (darn) (-E-IR¹²),PRETÉRITO IMPERFEITO",
    "6_v41,ANSIAR (long_for) (-IAR¹),@CONDICIONAL",
    "6_v42,SENTIR (feel) (-E-IR¹),@CONDICIONAL",
    "6_v43,AGREDIR (hit) (-E-IR²),@CONDICIONAL",
    "6_v44,FRIGIR (fry) (-I-IR¹),@CONDICIONAL",
    "6_v45,DORMIR (sleep) (-O-IR¹),@CONDICIONAL",
    "6_v46,POLIR (polish) (-O-IR²),@CONDICIONAL",
    "6_v47,SACUDIR (shake) (-U-IR¹),@CONDICIONAL",
    "6_v48,CERZIR (darn) (-E-IR¹²),@CONDICIONAL",
    "7_v41,ANSIAR (long_for) (-IAR¹),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v42,SENTIR (feel) (-E-IR¹),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v43,AGREDIR (hit) (-E-IR²),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v44,FRIGIR (fry) (-I-IR¹),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v45,DORMIR (sleep) (-O-IR¹),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v46,POLIR (polish) (-O-IR²),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v47,SACUDIR (shake) (-U-IR¹),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v48,CERZIR (darn) (-E-IR¹²),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "8_v41,ANSIAR (long_for) (-IAR¹),PRESENTE @CONJUNTIVO",
    "8_v42,SENTIR (feel) (-E-IR¹),PRESENTE @CONJUNTIVO",
    "8_v43,AGREDIR (hit) (-E-IR²),PRESENTE @CONJUNTIVO",
    "8_v44,FRIGIR (fry) (-I-IR¹),PRESENTE @CONJUNTIVO",
    "8_v45,DORMIR (sleep) (-O-IR¹),PRESENTE @CONJUNTIVO",
    "8_v46,POLIR (polish) (-O-IR²),PRESENTE @CONJUNTIVO",
    "8_v47,SACUDIR (shake) (-U-IR¹),PRESENTE @CONJUNTIVO",
    "8_v48,CERZIR (darn) (-E-IR¹²),PRESENTE @CONJUNTIVO",
    "9_v41,ANSIAR (long_for) (-IAR¹),FUTURO @CONJUNTIVO",
    "9_v42,SENTIR (feel) (-E-IR¹),FUTURO @CONJUNTIVO",
    "9_v43,AGREDIR (hit) (-E-IR²),FUTURO @CONJUNTIVO",
    "9_v44,FRIGIR (fry) (-I-IR¹),FUTURO @CONJUNTIVO",
    "9_v45,DORMIR (sleep) (-O-IR¹),FUTURO @CONJUNTIVO",
    "9_v46,POLIR (polish) (-O-IR²),FUTURO @CONJUNTIVO",
    "9_v47,SACUDIR (shake) (-U-IR¹),FUTURO @CONJUNTIVO",
    "9_v48,CERZIR (darn) (-E-IR¹²),FUTURO @CONJUNTIVO",
    "i_v41,ANSIAR (long_for) (-IAR¹),IMPERATIVO RESTRITO",
    "i_v42,SENTIR (feel) (-E-IR¹),IMPERATIVO RESTRITO",
    "i_v43,AGREDIR (hit) (-E-IR²),IMPERATIVO RESTRITO",
    "i_v44,FRIGIR (fry) (-I-IR¹),IMPERATIVO RESTRITO",
    "i_v45,DORMIR (sleep) (-O-IR¹),IMPERATIVO RESTRITO",
    "i_v46,POLIR (polish) (-O-IR²),IMPERATIVO RESTRITO",
    "i_v47,SACUDIR (shake) (-U-IR¹),IMPERATIVO RESTRITO",
    "i_v48,CERZIR (darn) (-E-IR¹²),IMPERATIVO RESTRITO",
    "n_v41,ANSIAR (long_for) (-IAR¹),INFINITIVO PESSOAL",
    "n_v42,SENTIR (feel) (-E-IR¹),INFINITIVO PESSOAL",
    "n_v43,AGREDIR (hit) (-E-IR²),INFINITIVO PESSOAL",
    "n_v44,FRIGIR (fry) (-I-IR¹),INFINITIVO PESSOAL",
    "n_v45,DORMIR (sleep) (-O-IR¹),INFINITIVO PESSOAL",
    "n_v46,POLIR (polish) (-O-IR²),INFINITIVO PESSOAL",
    "n_v47,SACUDIR (shake) (-U-IR¹),INFINITIVO PESSOAL",
    "n_v48,CERZIR (darn) (-E-IR¹²),INFINITIVO PESSOAL",
    "p_v41,ANSIAR (long_for) (-IAR¹),FORMAS NOMINAIS",
    "p_v42,SENTIR (feel) (-E-IR¹),FORMAS NOMINAIS",
    "p_v43,AGREDIR (hit) (-E-IR²),FORMAS NOMINAIS",
    "p_v44,FRIGIR (fry) (-I-IR¹),FORMAS NOMINAIS",
    "p_v45,DORMIR (sleep) (-O-IR¹),FORMAS NOMINAIS",
    "p_v46,POLIR (polish) (-O-IR²),FORMAS NOMINAIS",
    "p_v47,SACUDIR (shake) (-U-IR¹),FORMAS NOMINAIS",
    "p_v48,CERZIR (darn) (-E-IR¹²),FORMAS NOMINAIS",
];

export const tempos5 = [
    "1_v51,PERDER (lose) (*),PRETÉRITO PERFEITO",
    "1_v52,VALER (be_worth) (*),PRETÉRITO PERFEITO",
    "1_v53,MEDIR (measure) (*),PRETÉRITO PERFEITO",
    "1_v54,OUVIR (hear) (*),PRETÉRITO PERFEITO",
    "1_v55,PARIR (give_birth) (*),PRETÉRITO PERFEITO",
    "1_v56,REQUERER (request) (*),PRETÉRITO PERFEITO",
    "1_v57,LER (read) (*),PRETÉRITO PERFEITO",
    "1_v58,PROVER (provide) (*),PRETÉRITO PERFEITO",
    "1_v59,RIR (laugh) (*),PRETÉRITO PERFEITO",
    "2_v51,PERDER (lose) (*),PRESENTE",
    "2_v52,VALER (be_worth) (*),PRESENTE",
    "2_v53,MEDIR (measure) (*),PRESENTE",
    "2_v54,OUVIR (hear) (*),PRESENTE",
    "2_v55,PARIR (give_birth) (*),PRESENTE",
    "2_v56,REQUERER (request) (*),PRESENTE",
    "2_v57,LER (read) (*),PRESENTE",
    "2_v58,PROVER (provide) (*),PRESENTE",
    "2_v59,RIR (laugh) (*),PRESENTE",
    "3_v51,PERDER (lose) (*),FUTURO",
    "3_v52,VALER (be_worth) (*),FUTURO",
    "3_v53,MEDIR (measure) (*),FUTURO",
    "3_v54,OUVIR (hear) (*),FUTURO",
    "3_v55,PARIR (give_birth) (*),FUTURO",
    "3_v56,REQUERER (request) (*),FUTURO",
    "3_v57,LER (read) (*),FUTURO",
    "3_v58,PROVER (provide) (*),FUTURO",
    "3_v59,RIR (laugh) (*),FUTURO",
    "4_v51,PERDER (lose) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v52,VALER (be_worth) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v53,MEDIR (measure) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v54,OUVIR (hear) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v55,PARIR (give_birth) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v56,REQUERER (request) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v57,LER (read) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v58,PROVER (provide) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v59,RIR (laugh) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "5_v51,PERDER (lose) (*),PRETÉRITO IMPERFEITO",
    "5_v52,VALER (be_worth) (*),PRETÉRITO IMPERFEITO",
    "5_v53,MEDIR (measure) (*),PRETÉRITO IMPERFEITO",
    "5_v54,OUVIR (hear) (*),PRETÉRITO IMPERFEITO",
    "5_v55,PARIR (give_birth) (*),PRETÉRITO IMPERFEITO",
    "5_v56,REQUERER (request) (*),PRETÉRITO IMPERFEITO",
    "5_v57,LER (read) (*),PRETÉRITO IMPERFEITO",
    "5_v58,PROVER (provide) (*),PRETÉRITO IMPERFEITO",
    "5_v59,RIR (laugh) (*),PRETÉRITO IMPERFEITO",
    "6_v51,PERDER (lose) (*),@CONDICIONAL",
    "6_v52,VALER (be_worth) (*),@CONDICIONAL",
    "6_v53,MEDIR (measure) (*),@CONDICIONAL",
    "6_v54,OUVIR (hear) (*),@CONDICIONAL",
    "6_v55,PARIR (give_birth) (*),@CONDICIONAL",
    "6_v56,REQUERER (request) (*),@CONDICIONAL",
    "6_v57,LER (read) (*),@CONDICIONAL",
    "6_v58,PROVER (provide) (*),@CONDICIONAL",
    "6_v59,RIR (laugh) (*),@CONDICIONAL",
    "7_v51,PERDER (lose) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v52,VALER (be_worth) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v53,MEDIR (measure) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v54,OUVIR (hear) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v55,PARIR (give_birth) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v56,REQUERER (request) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v57,LER (read) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v58,PROVER (provide) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v59,RIR (laugh) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "8_v51,PERDER (lose) (*),PRESENTE @CONJUNTIVO",
    "8_v52,VALER (be_worth) (*),PRESENTE @CONJUNTIVO",
    "8_v53,MEDIR (measure) (*),PRESENTE @CONJUNTIVO",
    "8_v54,OUVIR (hear) (*),PRESENTE @CONJUNTIVO",
    "8_v55,PARIR (give_birth) (*),PRESENTE @CONJUNTIVO",
    "8_v56,REQUERER (request) (*),PRESENTE @CONJUNTIVO",
    "8_v57,LER (read) (*),PRESENTE @CONJUNTIVO",
    "8_v58,PROVER (provide) (*),PRESENTE @CONJUNTIVO",
    "8_v59,RIR (laugh) (*),PRESENTE @CONJUNTIVO",
    "9_v51,PERDER (lose) (*),FUTURO @CONJUNTIVO",
    "9_v52,VALER (be_worth) (*),FUTURO @CONJUNTIVO",
    "9_v53,MEDIR (measure) (*),FUTURO @CONJUNTIVO",
    "9_v54,OUVIR (hear) (*),FUTURO @CONJUNTIVO",
    "9_v55,PARIR (give_birth) (*),FUTURO @CONJUNTIVO",
    "9_v56,REQUERER (request) (*),FUTURO @CONJUNTIVO",
    "9_v57,LER (read) (*),FUTURO @CONJUNTIVO",
    "9_v58,PROVER (provide) (*),FUTURO @CONJUNTIVO",
    "9_v59,RIR (laugh) (*),FUTURO @CONJUNTIVO",
    "i_v51,PERDER (lose) (*),IMPERATIVO RESTRITO",
    "i_v52,VALER (be_worth) (*),IMPERATIVO RESTRITO",
    "i_v53,MEDIR (measure) (*),IMPERATIVO RESTRITO",
    "i_v54,OUVIR (hear) (*),IMPERATIVO RESTRITO",
    "i_v55,PARIR (give_birth) (*),IMPERATIVO RESTRITO",
    "i_v56,REQUERER (request) (*),IMPERATIVO RESTRITO",
    "i_v57,LER (read) (*),IMPERATIVO RESTRITO",
    "i_v58,PROVER (provide) (*),IMPERATIVO RESTRITO",
    "i_v59,RIR (laugh) (*),IMPERATIVO RESTRITO",
    "n_v51,PERDER (lose) (*),INFINITIVO PESSOAL",
    "n_v52,VALER (be_worth) (*),INFINITIVO PESSOAL",
    "n_v53,MEDIR (measure) (*),INFINITIVO PESSOAL",
    "n_v54,OUVIR (hear) (*),INFINITIVO PESSOAL",
    "n_v55,PARIR (give_birth) (*),INFINITIVO PESSOAL",
    "n_v56,REQUERER (request) (*),INFINITIVO PESSOAL",
    "n_v57,LER (read) (*),INFINITIVO PESSOAL",
    "n_v58,PROVER (provide) (*),INFINITIVO PESSOAL",
    "n_v59,RIR (laugh) (*),INFINITIVO PESSOAL",
    "p_v51,PERDER (lose) (*),FORMAS NOMINAIS",
    "p_v52,VALER (be_worth) (*),FORMAS NOMINAIS",
    "p_v53,MEDIR (measure) (*),FORMAS NOMINAIS",
    "p_v54,OUVIR (hear) (*),FORMAS NOMINAIS",
    "p_v55,PARIR (give_birth) (*),FORMAS NOMINAIS",
    "p_v56,REQUERER (request) (*),FORMAS NOMINAIS",
    "p_v57,LER (read) (*),FORMAS NOMINAIS",
    "p_v58,PROVER (provide) (*),FORMAS NOMINAIS",
    "p_v59,RIR (laugh) (*),FORMAS NOMINAIS",
];

export const tempos6 = [
    "1_v61,COMPRAZER (please) (*),PRETÉRITO PERFEITO",
    "1_v62,CABER (fit) (*),PRETÉRITO PERFEITO",
    "1_v63,DAR (give) (*),PRETÉRITO PERFEITO",
    "1_v64,PODER (be_able_to) (*),PRETÉRITO PERFEITO",
    "1_v65,VER (see) (*),PRETÉRITO PERFEITO",
    "1_v66,DIZER (say) (*),PRETÉRITO PERFEITO",
    "1_v67,FAZER (do) (*),PRETÉRITO PERFEITO",
    "1_v68,TRAZER (bring) (*),PRETÉRITO PERFEITO",
    "1_v69,TER (have) (*),PRETÉRITO PERFEITO",
    "1_v6a,VIR (come) (*),PRETÉRITO PERFEITO",
    "1_v6b,PÔR (place) (-POR),PRETÉRITO PERFEITO",
    "2_v61,COMPRAZER (please) (*),PRESENTE",
    "2_v62,CABER (fit) (*),PRESENTE",
    "2_v63,DAR (give) (*),PRESENTE",
    "2_v64,PODER (be_able_to) (*),PRESENTE",
    "2_v65,VER (see) (*),PRESENTE",
    "2_v66,DIZER (say) (*),PRESENTE",
    "2_v67,FAZER (do) (*),PRESENTE",
    "2_v68,TRAZER (bring) (*),PRESENTE",
    "2_v69,TER (have) (*),PRESENTE",
    "2_v6a,VIR (come) (*),PRESENTE",
    "2_v6b,PÔR (place) (-POR),PRESENTE",
    "3_v61,COMPRAZER (please) (*),FUTURO",
    "3_v62,CABER (fit) (*),FUTURO",
    "3_v63,DAR (give) (*),FUTURO",
    "3_v64,PODER (be_able_to) (*),FUTURO",
    "3_v65,VER (see) (*),FUTURO",
    "3_v66,DIZER (say) (*),FUTURO",
    "3_v67,FAZER (do) (*),FUTURO",
    "3_v68,TRAZER (bring) (*),FUTURO",
    "3_v69,TER (have) (*),FUTURO",
    "3_v6a,VIR (come) (*),FUTURO",
    "3_v6b,PÔR (place) (-POR),FUTURO",
    "4_v61,COMPRAZER (please) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v62,CABER (fit) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v63,DAR (give) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v64,PODER (be_able_to) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v65,VER (see) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v66,DIZER (say) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v67,FAZER (do) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v68,TRAZER (bring) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v69,TER (have) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v6a,VIR (come) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v6b,PÔR (place) (-POR),PRETÉRITO MAIS-QUE-PERFEITO",
    "5_v61,COMPRAZER (please) (*),PRETÉRITO IMPERFEITO",
    "5_v62,CABER (fit) (*),PRETÉRITO IMPERFEITO",
    "5_v63,DAR (give) (*),PRETÉRITO IMPERFEITO",
    "5_v64,PODER (be_able_to) (*),PRETÉRITO IMPERFEITO",
    "5_v65,VER (see) (*),PRETÉRITO IMPERFEITO",
    "5_v66,DIZER (say) (*),PRETÉRITO IMPERFEITO",
    "5_v67,FAZER (do) (*),PRETÉRITO IMPERFEITO",
    "5_v68,TRAZER (bring) (*),PRETÉRITO IMPERFEITO",
    "5_v69,TER (have) (*),PRETÉRITO IMPERFEITO",
    "5_v6a,VIR (come) (*),PRETÉRITO IMPERFEITO",
    "5_v6b,PÔR (place) (-POR),PRETÉRITO IMPERFEITO",
    "6_v61,COMPRAZER (please) (*),@CONDICIONAL",
    "6_v62,CABER (fit) (*),@CONDICIONAL",
    "6_v63,DAR (give) (*),@CONDICIONAL",
    "6_v64,PODER (be_able_to) (*),@CONDICIONAL",
    "6_v65,VER (see) (*),@CONDICIONAL",
    "6_v66,DIZER (say) (*),@CONDICIONAL",
    "6_v67,FAZER (do) (*),@CONDICIONAL",
    "6_v68,TRAZER (bring) (*),@CONDICIONAL",
    "6_v69,TER (have) (*),@CONDICIONAL",
    "6_v6a,VIR (come) (*),@CONDICIONAL",
    "6_v6b,PÔR (place) (-POR),@CONDICIONAL",
    "7_v61,COMPRAZER (please) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v62,CABER (fit) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v63,DAR (give) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v64,PODER (be_able_to) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v65,VER (see) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v66,DIZER (say) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v67,FAZER (do) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v68,TRAZER (bring) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v69,TER (have) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v6a,VIR (come) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v6b,PÔR (place) (-POR),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "8_v61,COMPRAZER (please) (*),PRESENTE @CONJUNTIVO",
    "8_v62,CABER (fit) (*),PRESENTE @CONJUNTIVO",
    "8_v63,DAR (give) (*),PRESENTE @CONJUNTIVO",
    "8_v64,PODER (be_able_to) (*),PRESENTE @CONJUNTIVO",
    "8_v65,VER (see) (*),PRESENTE @CONJUNTIVO",
    "8_v66,DIZER (say) (*),PRESENTE @CONJUNTIVO",
    "8_v67,FAZER (do) (*),PRESENTE @CONJUNTIVO",
    "8_v68,TRAZER (bring) (*),PRESENTE @CONJUNTIVO",
    "8_v69,TER (have) (*),PRESENTE @CONJUNTIVO",
    "8_v6a,VIR (come) (*),PRESENTE @CONJUNTIVO",
    "8_v6b,PÔR (place) (-POR),PRESENTE @CONJUNTIVO",
    "9_v61,COMPRAZER (please) (*),FUTURO @CONJUNTIVO",
    "9_v62,CABER (fit) (*),FUTURO @CONJUNTIVO",
    "9_v63,DAR (give) (*),FUTURO @CONJUNTIVO",
    "9_v64,PODER (be_able_to) (*),FUTURO @CONJUNTIVO",
    "9_v65,VER (see) (*),FUTURO @CONJUNTIVO",
    "9_v66,DIZER (say) (*),FUTURO @CONJUNTIVO",
    "9_v67,FAZER (do) (*),FUTURO @CONJUNTIVO",
    "9_v68,TRAZER (bring) (*),FUTURO @CONJUNTIVO",
    "9_v69,TER (have) (*),FUTURO @CONJUNTIVO",
    "9_v6a,VIR (come) (*),FUTURO @CONJUNTIVO",
    "9_v6b,PÔR (place) (-POR),FUTURO @CONJUNTIVO",
    "i_v61,COMPRAZER (please) (*),IMPERATIVO RESTRITO",
    "i_v62,CABER (fit) (*),IMPERATIVO RESTRITO",
    "i_v63,DAR (give) (*),IMPERATIVO RESTRITO",
    "i_v64,PODER (be_able_to) (*),IMPERATIVO RESTRITO",
    "i_v65,VER (see) (*),IMPERATIVO RESTRITO",
    "i_v66,DIZER (say) (*),IMPERATIVO RESTRITO",
    "i_v67,FAZER (do) (*),IMPERATIVO RESTRITO",
    "i_v68,TRAZER (bring) (*),IMPERATIVO RESTRITO",
    "i_v69,TER (have) (*),IMPERATIVO RESTRITO",
    "i_v6a,VIR (come) (*),IMPERATIVO RESTRITO",
    "i_v6b,PÔR (place) (-POR),IMPERATIVO RESTRITO",
    "n_v61,COMPRAZER (please) (*),INFINITIVO PESSOAL",
    "n_v62,CABER (fit) (*),INFINITIVO PESSOAL",
    "n_v63,DAR (give) (*),INFINITIVO PESSOAL",
    "n_v64,PODER (be_able_to) (*),INFINITIVO PESSOAL",
    "n_v65,VER (see) (*),INFINITIVO PESSOAL",
    "n_v66,DIZER (say) (*),INFINITIVO PESSOAL",
    "n_v67,FAZER (do) (*),INFINITIVO PESSOAL",
    "n_v68,TRAZER (bring) (*),INFINITIVO PESSOAL",
    "n_v69,TER (have) (*),INFINITIVO PESSOAL",
    "n_v6a,VIR (come) (*),INFINITIVO PESSOAL",
    "n_v6b,PÔR (place) (-POR),INFINITIVO PESSOAL",
    "p_v61,COMPRAZER (please) (*),FORMAS NOMINAIS",
    "p_v62,CABER (fit) (*),FORMAS NOMINAIS",
    "p_v63,DAR (give) (*),FORMAS NOMINAIS",
    "p_v64,PODER (be_able_to) (*),FORMAS NOMINAIS",
    "p_v65,VER (see) (*),FORMAS NOMINAIS",
    "p_v66,DIZER (say) (*),FORMAS NOMINAIS",
    "p_v67,FAZER (do) (*),FORMAS NOMINAIS",
    "p_v68,TRAZER (bring) (*),FORMAS NOMINAIS",
    "p_v69,TER (have) (*),FORMAS NOMINAIS",
    "p_v6a,VIR (come) (*),FORMAS NOMINAIS",
    "p_v6b,PÔR (place) (-POR),FORMAS NOMINAIS",
];

export const tempos7 = [
    "1_v71,QUERER (want) (*),PRETÉRITO PERFEITO",
    "1_v72,ESTAR (be_[occasional]) (*),PRETÉRITO PERFEITO",
    "1_v73,HAVER (there_to_be) (*),PRETÉRITO PERFEITO",
    "1_v74,SABER (know) (*),PRETÉRITO PERFEITO",
    "1_v75,IR (go) (*),PRETÉRITO PERFEITO",
    "1_v76,SER (be_[essence]) (*),PRETÉRITO PERFEITO",
    "2_v71,QUERER (want) (*),PRESENTE",
    "2_v72,ESTAR (be_[occasional]) (*),PRESENTE",
    "2_v73,HAVER (there_to_be) (*),PRESENTE",
    "2_v74,SABER (know) (*),PRESENTE",
    "2_v75,IR (go) (*),PRESENTE",
    "2_v76,SER (be_[essence]) (*),PRESENTE",
    "3_v71,QUERER (want) (*),FUTURO",
    "3_v72,ESTAR (be_[occasional]) (*),FUTURO",
    "3_v73,HAVER (there_to_be) (*),FUTURO",
    "3_v74,SABER (know) (*),FUTURO",
    "3_v75,IR (go) (*),FUTURO",
    "3_v76,SER (be_[essence]) (*),FUTURO",
    "4_v71,QUERER (want) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v72,ESTAR (be_[occasional]) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v73,HAVER (there_to_be) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v74,SABER (know) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v75,IR (go) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "4_v76,SER (be_[essence]) (*),PRETÉRITO MAIS-QUE-PERFEITO",
    "5_v71,QUERER (want) (*),PRETÉRITO IMPERFEITO",
    "5_v72,ESTAR (be_[occasional]) (*),PRETÉRITO IMPERFEITO",
    "5_v73,HAVER (there_to_be) (*),PRETÉRITO IMPERFEITO",
    "5_v74,SABER (know) (*),PRETÉRITO IMPERFEITO",
    "5_v75,IR (go) (*),PRETÉRITO IMPERFEITO",
    "5_v76,SER (be_[essence]) (*),PRETÉRITO IMPERFEITO",
    "6_v71,QUERER (want) (*),@CONDICIONAL",
    "6_v72,ESTAR (be_[occasional]) (*),@CONDICIONAL",
    "6_v73,HAVER (there_to_be) (*),@CONDICIONAL",
    "6_v74,SABER (know) (*),@CONDICIONAL",
    "6_v75,IR (go) (*),@CONDICIONAL",
    "6_v76,SER (be_[essence]) (*),@CONDICIONAL",
    "7_v71,QUERER (want) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v72,ESTAR (be_[occasional]) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v73,HAVER (there_to_be) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v74,SABER (know) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v75,IR (go) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "7_v76,SER (be_[essence]) (*),PRETÉRITO IMPERFEITO @CONJUNTIVO",
    "8_v71,QUERER (want) (*),PRESENTE @CONJUNTIVO",
    "8_v72,ESTAR (be_[occasional]) (*),PRESENTE @CONJUNTIVO",
    "8_v73,HAVER (there_to_be) (*),PRESENTE @CONJUNTIVO",
    "8_v74,SABER (know) (*),PRESENTE @CONJUNTIVO",
    "8_v75,IR (go) (*),PRESENTE @CONJUNTIVO",
    "8_v76,SER (be_[essence]) (*),PRESENTE @CONJUNTIVO",
    "9_v71,QUERER (want) (*),FUTURO @CONJUNTIVO",
    "9_v72,ESTAR (be_[occasional]) (*),FUTURO @CONJUNTIVO",
    "9_v73,HAVER (there_to_be) (*),FUTURO @CONJUNTIVO",
    "9_v74,SABER (know) (*),FUTURO @CONJUNTIVO",
    "9_v75,IR (go) (*),FUTURO @CONJUNTIVO",
    "9_v76,SER (be_[essence]) (*),FUTURO @CONJUNTIVO",
    "i_v71,QUERER (want) (*),IMPERATIVO RESTRITO",
    "i_v72,ESTAR (be_[occasional]) (*),IMPERATIVO RESTRITO",
    "i_v73,HAVER (there_to_be) (*),IMPERATIVO RESTRITO",
    "i_v74,SABER (know) (*),IMPERATIVO RESTRITO",
    "i_v75,IR (go) (*),IMPERATIVO RESTRITO",
    "i_v76,SER (be_[essence]) (*),IMPERATIVO RESTRITO",
    "n_v71,QUERER (want) (*),INFINITIVO PESSOAL",
    "n_v72,ESTAR (be_[occasional]) (*),INFINITIVO PESSOAL",
    "n_v73,HAVER (there_to_be) (*),INFINITIVO PESSOAL",
    "n_v74,SABER (know) (*),INFINITIVO PESSOAL",
    "n_v75,IR (go) (*),INFINITIVO PESSOAL",
    "n_v76,SER (be_[essence]) (*),INFINITIVO PESSOAL",
    "p_v71,QUERER (want) (*),FORMAS NOMINAIS",
    "p_v72,ESTAR (be_[occasional]) (*),FORMAS NOMINAIS",
    "p_v73,HAVER (there_to_be) (*),FORMAS NOMINAIS",
    "p_v74,SABER (know) (*),FORMAS NOMINAIS",
    "p_v75,IR (go) (*),FORMAS NOMINAIS",
    "p_v76,SER (be_[essence]) (*),FORMAS NOMINAIS",
];

export function Verbos(entrada) {
    const parts = entrada.split(",", 3);
    return parts[1];
}

export function Tempos(entrada) {
    console.log(101)
    console.log(entrada);
    const parts = entrada.split(",", 3);
    return parts[2];
}

export function VerbosTempos(entrada) {
    const partes = entrada.split(",");
    const subPartes = partes[0].split("_");
    return `${subPartes[1]}_${subPartes[0]},${partes[1]},${partes[2]}`;
}

export function CorrigirTempo(VelhoTempo){
    ActivityConfig = JSON.parse(localStorage.getItem('config'));
    let NovoTempo = VelhoTempo;
    if (ActivityConfig.PaisAudio === "Brasil") {
        NovoTempo = NovoTempo.replace(/@CONJUNTIVO/g, "DO SUBJUNTIVO");
        NovoTempo = NovoTempo.replace(/@CONDICIONAL/g, "FUTURO DO PRETÉRITO");
    } else if (ActivityConfig.PaisAudio === "Portugal") {
        NovoTempo = NovoTempo.replace(/@CONJUNTIVO/g, "DO CONJUNTIVO");
        NovoTempo = NovoTempo.replace(/@CONDICIONAL/g, "CONDICIONAL");
    }
    return NovoTempo;
}



