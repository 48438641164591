import React, {useState, useEffect, useRef} from 'react';
import LazyLoad from 'react-lazyload';
import { Button, Table, Image } from 'react-bootstrap';
import './styles.css'; // Importe o arquivo CSS
import previousIcon from './Icons/previousIcon.png'; // O caminho para o ícone anterior
import nextIcon from './Icons/nextIcon.png'; // O caminho para o ícone próximo
import repeatIcon from './Icons/repeatIcon.png';
import stopIcon from './Icons/stopIcon.png';
import { verbosq1, temposq1, VerbosQ, TemposQ, VerbosTemposQ } from './conjugacao3';
import {paradigmasq, Terminacoes, Pagina, VerbosTemposParaVerbosQS} from "./paradigmas"
// import logo from './logotipo2.png'; // O caminho para a imagem
// import paypalIcon from "./Icons/paypalIcon.png";

const ActivityFall = ({ goBack }) => {

    const logoRef=useRef('./logotipo2.png');
    const paypalIconRef=useRef('./Icons/paypalIcon.png');

    ////Variáveis do quadro principal
    const ordemVerbosQRef = useRef(0);
    const ordemTemposQRef = useRef(0);
    const numeroTemposQRef = useRef(0);
    const numeroVerbosQRef = useRef(0);
    const verboQRef = useRef('AMAR (To love)');
    const verboQPRef =useRef('AMAR');
    const verboQIRef =useRef('(To love)');
    const verboQTRef =useRef ('(-AR)');
    const tempoQRef = useRef('Presente');
    const arquivoQRef = useRef("logotipo2");
    const cBSPassarVerboQRef = useRef(false);
    const cBSPassarTempoQRef = useRef(false);
    const conjugacaoVerbosQRef = useRef(verbosq1);
    const conjugacaoTemposQRef = useRef(temposq1);
    const audiosQUrlRef = useRef([]); // Adicione esta referência no início do seu componente
    const audiosQRef = useRef([]);
    const currentAudioIndexQRef =useRef(0);
    //// Variáveis do quadro de seguidores de paradigmas
    const arquivoQSRef = useRef("logotipo2");
    const verboQSRef = useRef('-AR');
    const paginaQSRef = useRef('(1/1)');
    const listaPVerbosQSRef =useRef(paradigmasq);
    const listaVerboQSRef = useRef(paradigmasq);
    const ordemVerbosQSRef = useRef(0);
    //// Variáveis de ambos os quadros
    const [renderSt, setRender] = useState(0);
    const loadingRef = useRef(false);
    const hasRunRef = useRef(false);
    const tempoDesabilitadoQRef = useRef(1000);


    //// Funções do quadro principal
    const atualizarVetorVerbosQS = () => {
        let verboParadigmaQ = VerbosTemposParaVerbosQS(conjugacaoVerbosQRef.current[ordemVerbosQRef.current]).substring(0, 2);
        listaVerboQSRef.current = listaPVerbosQSRef.current.filter(item => item.startsWith(verboParadigmaQ));
    }

    // Função para atualizar os TemposQ
    const atualizarTemposQ =() => {
        if (ordemVerbosQRef.current >= conjugacaoVerbosQRef.current.length) {
            ordemVerbosQRef.current=0;
        }
        arquivoQRef.current=conjugacaoVerbosQRef.current[ordemVerbosQRef.current].substring(0, 4);

        verboQRef.current=VerbosQ(conjugacaoVerbosQRef.current[ordemVerbosQRef.current]);
        verboQPRef.current=verboQRef.current.substring(0,verboQRef.current.indexOf(" "));
        verboQIRef.current=verboQRef.current.substring(verboQRef.current.indexOf(" ")+1);
        verboQTRef.current=verboQIRef.current.substring(verboQIRef.current.indexOf(" ")+1);
        verboQIRef.current=verboQIRef.current.substring(0,verboQIRef.current.indexOf(" "));
        tempoQRef.current=TemposQ(conjugacaoVerbosQRef.current[ordemVerbosQRef.current]);

        // Atualize OrdemTemposQ
        ordemTemposQRef.current= conjugacaoTemposQRef.current.findIndex(tempoX => VerbosTemposQ(tempoX) === conjugacaoVerbosQRef.current[ordemVerbosQRef.current]);
        atualizarVetorVerbosQS();
        ordemVerbosQSRef.current=listaVerboQSRef.current.findIndex(verboQSX => verboQSX.substring(0,2)===VerbosTemposParaVerbosQS(conjugacaoVerbosQRef.current[ordemVerbosQRef.current]).substring(0,2));
    };

    // Função para atualizar os verbos
    const atualizarVerbosQ = () => {
        if (ordemTemposQRef.current >= conjugacaoTemposQRef.current.length) {
            ordemTemposQRef.current = 0;
        }

        arquivoQRef.current=VerbosTemposQ(conjugacaoTemposQRef.current[ordemTemposQRef.current]).substring(0, 4);
        verboQRef.current=VerbosQ(conjugacaoTemposQRef.current[ordemTemposQRef.current]);
        verboQPRef.current=verboQRef.current.substring(0,verboQRef.current.indexOf(" "));
        verboQIRef.current=verboQRef.current.substring(verboQRef.current.indexOf(" ")+1);
        verboQTRef.current=verboQIRef.current.substring(verboQIRef.current.indexOf(" ")+1);
        verboQIRef.current=verboQIRef.current.substring(0,verboQIRef.current.indexOf(" "));
        tempoQRef.current=TemposQ(conjugacaoTemposQRef.current[ordemTemposQRef.current]);

        // Atualize OrdemVerbosQ
        ordemVerbosQRef.current = conjugacaoVerbosQRef.current.findIndex(verboX => VerbosTemposQ(verboX) === conjugacaoTemposQRef.current[ordemTemposQRef.current]);
        atualizarVetorVerbosQS();
        ordemVerbosQSRef.current=listaVerboQSRef.current.findIndex(verboQSX => verboQSX.substring(0,2)===VerbosTemposParaVerbosQS(conjugacaoTemposQRef.current[ordemTemposQRef.current]).substring(0,2));
    };
    const pararAudiosQ = () =>{
        if (currentAudioIndexQRef.current < audiosQRef.current.length){
            if (!audiosQRef.current[currentAudioIndexQRef.current].paused) {
                audiosQRef.current[currentAudioIndexQRef.current].pause();
                audiosQRef.current[currentAudioIndexQRef.current].currentTime = 0;
            }
            // Aguarda o término do áudio atual antes de tocar o próximo

            currentAudioIndexQRef.current++;
            pararAudiosQ();
        }
    }


    const tocarConjugacao = () => {
        let arquivoAudioQ, arquivoAudioQ0, urlAudioQ, urlAudioQ0, urlAudioQ00;
        let ActivityConfig = JSON.parse(localStorage.getItem('config'));
        if (ActivityConfig.PaisAudio === "Portugal"){
            arquivoAudioQ = "p" + arquivoQRef.current;
            arquivoAudioQ0 = "p0" + arquivoQRef.current;
        } else {
            arquivoAudioQ = "b" + arquivoQRef.current;
            arquivoAudioQ0 = "b0" + arquivoQRef.current;
        }

        urlAudioQ = process.env.PUBLIC_URL + `/raw/${arquivoAudioQ}.mp3`;
        urlAudioQ0 = process.env.PUBLIC_URL + `/raw/${arquivoAudioQ0}.mp3`;
        urlAudioQ00 = process.env.PUBLIC_URL + `/raw/virada.mp3`;
        // Defina AudiosQRef.current aqui
        const construirVetoresQ = () => {
            let playCount=0;
            audiosQUrlRef.current=[];
            audiosQRef.current=[];
            while (playCount < ActivityConfig.NumeroAudios) {
                if(ActivityConfig.LerTempo==="Nunca"){
                    audiosQUrlRef.current.push(urlAudioQ);
                } else if((ActivityConfig.LerTempo==="Uma Vez")&&playCount===0){
                    audiosQUrlRef.current.push(urlAudioQ0,urlAudioQ);
                } else if((ActivityConfig.LerTempo==="Uma Vez")&&playCount>0){
                    audiosQUrlRef.current.push(urlAudioQ);
                } else{
                    audiosQUrlRef.current.push(urlAudioQ0,urlAudioQ);
                }
                playCount++;
            }
            console.log(3999);
            if (ActivityConfig.NumeroAudios==="0"){
                console.log(4000);
                audiosQUrlRef.current=[urlAudioQ00];
                console.log(4001);
            }
            audiosQRef.current = audiosQUrlRef.current.map((url) => new Audio(url));
        };
        const tocarAudiosQ = () => {
            if (currentAudioIndexQRef.current < audiosQRef.current.length) {
                let audioAtual = audiosQRef.current[currentAudioIndexQRef.current]
                audioAtual.playbackRate = ActivityConfig.VelocidadeAudio / 100;
                audioAtual.play();

                // Aguarda o término do áudio atual antes de tocar o próximo
                audioAtual.onended = function() {
                    currentAudioIndexQRef.current++;
                    tocarAudiosQ();
                };

            } else if (cBSPassarVerboQRef.current === true) {
                document.getElementById("irVerboQPosterior").click();
            } else if (cBSPassarTempoQRef.current === true) {
                document.getElementById("irTempoQPosterior").click();
            }

        };
        currentAudioIndexQRef.current=0;
        pararAudiosQ();
        construirVetoresQ();
        currentAudioIndexQRef.current=0;
        tocarAudiosQ();

    };

    const atualizarVerbosQS = () => {
        console.log(11);
        atualizarVetorVerbosQS();
        console.log(12);
        console.log(listaVerboQSRef.current);
        console.log(ordemVerbosQSRef.current);
        verboQSRef.current = listaVerboQSRef.current[ordemVerbosQSRef.current];
        console.log(13);
        console.log(verboQSRef.current);
        verboQSRef.current = Terminacoes(verboQSRef.current)+"\u00A0".repeat(4)+Pagina(verboQSRef.current);
        console.log(14);
        arquivoQSRef.current = listaVerboQSRef.current[ordemVerbosQSRef.current].substring(0,4);
        console.log(15);
        paginaQSRef.current = Pagina(listaVerboQSRef.current[ordemVerbosQSRef.current]);
        console.log(16);
        ordemVerbosQSRef.current = listaVerboQSRef.current.findIndex(paradigmaQX => paradigmaQX === listaVerboQSRef.current[ordemVerbosQSRef.current]);
        console.log(17);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    ////Funções para ambos os quadros
    const aplicarMudancaQ = () => {
        conjugacaoVerbosQRef.current=verbosq1;
        numeroTemposQRef.current=2;
        conjugacaoTemposQRef.current=temposq1;
        numeroVerbosQRef.current=4;

        // Chame as funções após a atualização do estado
        console.log(1);
        atualizarTemposQ();
        console.log(2);
        atualizarVerbosQS();
        console.log(3);
        tocarConjugacao();
        console.log(4);
    }
    const forceRender = () => {
        //hasRunRef.current = false; // Atualize hasRunRef para false
        aplicarMudancaQ();
        setRender(renderSt + 1); // Força a renderização
    };
    const forceRenderLocal = () => {
        //hasRunRef.current = false; // Atualize hasRunRef para false
        setRender(renderSt + 1); // Força a renderização
    };

    //Botões do quadro principal
    const handleTemposQAnteriorClick = (saltar) => {
        let newOrdemVerbosQ =ordemVerbosQRef.current - 1;

        if (newOrdemVerbosQ < 0) { newOrdemVerbosQ = conjugacaoVerbosQRef.current.length - 1; }

        ordemVerbosQRef.current= newOrdemVerbosQ;
        if (saltar===true){
        } else{
            document.getElementById('irVerboQPosterior').disabled=true;
            document.getElementById('irVerboQAnterior').disabled=true;
            document.getElementById('irTempoQPosterior').disabled=true;
            document.getElementById('irTempoQAnterior').disabled=true;
            document.getElementById('sair').disabled=true;
            forceRender();
            setTimeout(() => {
                document.getElementById('irVerboQPosterior').disabled=false;
                document.getElementById('irVerboQAnterior').disabled=false;
                document.getElementById('irTempoQPosterior').disabled=false;
                document.getElementById('irTempoQAnterior').disabled=false;
                document.getElementById('sair').disabled=false;
            }, tempoDesabilitadoQRef.current);
        }
    };

    const handleTemposQPosteriorClick = (saltar) => {
        let newOrdemVerbosQ =ordemVerbosQRef.current + 1;

        if (newOrdemVerbosQ >= conjugacaoVerbosQRef.current.length) { newOrdemVerbosQ = 0; }

        ordemVerbosQRef.current= newOrdemVerbosQ;
        if (saltar===true){
        } else{
            document.getElementById('irVerboQPosterior').disabled=true;
            document.getElementById('irVerboQAnterior').disabled=true;
            document.getElementById('irTempoQPosterior').disabled=true;
            document.getElementById('irTempoQAnterior').disabled=true;
            document.getElementById('sair').disabled=true;
            forceRender();
            setTimeout(() => {
                document.getElementById('irVerboQPosterior').disabled=false;
                document.getElementById('irVerboQAnterior').disabled=false;
                document.getElementById('irTempoQPosterior').disabled=false;
                document.getElementById('irTempoQAnterior').disabled=false;
                document.getElementById('sair').disabled=false;
            }, tempoDesabilitadoQRef.current);
        }
    };

    const handleVerbosQAnteriorClick = (saltar) => {
        let newOrdemTemposQ = ordemTemposQRef.current - 1;

        if (newOrdemTemposQ < 0) { newOrdemTemposQ = conjugacaoTemposQRef.current.length - 1; }

        ordemTemposQRef.current= newOrdemTemposQ;
        atualizarVerbosQ();
        if (saltar===true){
        } else{
            document.getElementById('irVerboQPosterior').disabled=true;
            document.getElementById('irVerboQAnterior').disabled=true;
            document.getElementById('irTempoQPosterior').disabled=true;
            document.getElementById('irTempoQAnterior').disabled=true;
            document.getElementById('sair').disabled=true;
            forceRender();
            setTimeout(() => {
                document.getElementById('irVerboQPosterior').disabled=false;
                document.getElementById('irVerboQAnterior').disabled=false;
                document.getElementById('irTempoQPosterior').disabled=false;
                document.getElementById('irTempoQAnterior').disabled=false;
                document.getElementById('sair').disabled=false;
            }, tempoDesabilitadoQRef.current);
        }
    };

    const handleVerbosQPosteriorClick = (saltar) => {
        let newOrdemTemposQ = ordemTemposQRef.current + 1;

        if (newOrdemTemposQ >= conjugacaoTemposQRef.current.length) { newOrdemTemposQ = 0; }

        ordemTemposQRef.current= newOrdemTemposQ;
        atualizarVerbosQ();
        if (saltar===true){
        } else{
            document.getElementById('irVerboQPosterior').disabled=true;
            document.getElementById('irVerboQAnterior').disabled=true;
            document.getElementById('irTempoQPosterior').disabled=true;
            document.getElementById('irTempoQAnterior').disabled=true;
            document.getElementById('sair').disabled=true;
            forceRender();
            setTimeout(() => {
                document.getElementById('irVerboQPosterior').disabled=false;
                document.getElementById('irVerboQAnterior').disabled=false;
                document.getElementById('irTempoQPosterior').disabled=false;
                document.getElementById('irTempoQAnterior').disabled=false;
                document.getElementById('sair').disabled=false;
            }, tempoDesabilitadoQRef.current);
        }
    };
    const handleSwitchVerbosQChange = () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks

        cBSPassarVerboQRef.current=!cBSPassarVerboQRef.current;
        forceRenderLocal();
        if(cBSPassarVerboQRef.current === true) {
            cBSPassarTempoQRef.current=false;
            pararAudiosQ();
            handleVerbosQAnteriorClick(true);
            handleVerbosQPosteriorClick();
        }
    };

    const handleSwitchTemposQChange = () => {
        cBSPassarTempoQRef.current=!cBSPassarTempoQRef.current;
        forceRenderLocal();
        if(cBSPassarTempoQRef.current === true) {
            cBSPassarVerboQRef.current=false;
            pararAudiosQ();
            handleTemposQAnteriorClick(true);
            handleTemposQPosteriorClick();
        }
    };

    const handleExitClick = () => {
        currentAudioIndexQRef.current=0
        pararAudiosQ();
        goBack();
    };

    const handleRepeatQClick = () => {
        tocarConjugacao();
    }

    const handleStopQClick = () => {
        pararAudiosQ();
    }

    //Botões dos seguidores de paradigma
    const handleSeguidorQAnteriorClick = () => {
        let newOrdemVerbosQS =ordemVerbosQSRef.current - 1;

        if (newOrdemVerbosQS < 0) { newOrdemVerbosQS = listaVerboQSRef.current.length - 1; }

        ordemVerbosQSRef.current= newOrdemVerbosQS;
        atualizarVerbosQS();
        forceRenderLocal();
    };

    const handleSeguidorQPosteriorClick = () => {
        let newOrdemVerbosQS =ordemVerbosQSRef.current + 1;

        if (newOrdemVerbosQS>= listaVerboQSRef.current.length) { newOrdemVerbosQS = 0; }

        ordemVerbosQSRef.current= newOrdemVerbosQS;
        atualizarVerbosQS();
        forceRenderLocal();
    };

    // Atualize os estados com base na seleção quando o componente for montado ou a seleção mudar
    useEffect(() => {
        if (!hasRunRef.current) {
            forceRender();
            hasRunRef.current = true;
        }
        loadingRef.current=true;
    });

    if (loadingRef.current===false) {
        return null; // Ou seu componente de carregamento personalizado
    }

    return (
        <div className="App">
            <div className="column left" style={{alignItems: 'center',  justifyContent: 'center', height: '100vh'}}>
                <table>
                    <tbody>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px', hyphens: "none"}}>
                            <h3><a href="https://www.paypal.com/donate/?hosted_button_id=T5WKS4MVY7VDE" target="_blank" rel="noopener noreferrer">Doação:</a></h3>
                        </td>
                    </tr>
                    <tr style={{height: '5px'}}>

                        <td style={{height: '5px'}}>
                            <p><a href="https://www.paypal.com/donate/?hosted_button_id=T5WKS4MVY7VDE" target="_blank" rel="noopener noreferrer">
                                <img src={require(`${paypalIconRef.current}`)} alt="Ícone do PayPal" style={{width: '200px', height: '200px'}}/>
                            </a></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="column center2"  style={{flexDirection: 'column', alignItems: 'center'}}>
                <h3 style= {{marginTop: '0', marginBottom: '0'}}>{verboQSRef.current}</h3>
                <Image
                    src={process.env.PUBLIC_URL + `/drawable2/${arquivoQSRef.current}.png`}
                    alt="Paradigmas"
                    onError={(e) => {e.target.onerror = null; e.target.src=require(`${logoRef.current}`)}}
                    style={{width: '260px', height: '459px', position: 'absolute', bottom: '45px'}}
                />
                <Table style={{position: 'absolute', bottom: '3px'}}>
                    <tbody>
                    <tr>
                        <td>
                            <Button id="irSeguidorAnterior" onClick={handleSeguidorQAnteriorClick} style={{ width: '25px', height: '25px', fontSize: '18px', marginRight: '20px', backgroundImage: `url(${previousIcon})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></Button>
                        </td>
                        <td>
                            <Button id="irSeguidorPosterior" onClick={handleSeguidorQPosteriorClick} style={{ width: '25px', height: '25px', fontSize: '18px', backgroundImage: `url(${nextIcon})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></Button>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>
            <div className="column center"  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <h3 style={{marginTop: '0' }}>QUEDA DO E</h3>
                <Table style={{ margin: '0' }}>
                    <tbody>
                    <tr>
                        <td>{'\u00A0'.repeat(9)}</td>
                        <td>{'\u00A0'.repeat(9)}</td>
                        <td style={{ display: 'flex', justifyContent: 'center' }}>
                            <span style={{ width: '10px' }}></span>
                            <Button id="irVerboQAnterior" onClick={handleVerbosQAnteriorClick} style={{ width: '25px', height: '25px', fontSize: '18px', backgroundImage: `url(${previousIcon})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></Button>
                            <span style={{ width: '10px' }}></span>
                            <Button id="irVerboQPosterior" onClick={handleVerbosQPosteriorClick} style={{ width: '25px', height: '25px', fontSize: '18px', backgroundImage: `url(${nextIcon})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></Button>
                        </td>
                        <td style={{verticalAlign: 'middle'}}>
                            <label className="switch">
                                <input type="checkbox" id="passarVerbosQ" checked={cBSPassarVerboQRef.current} onChange={handleSwitchVerbosQChange}/>
                                <span className="slider round"></span>
                            </label>
                        </td>
                        <td>Passar V.</td>
                    </tr>
                    </tbody>
                </Table>
                <h3 style={{marginBottom: '0'}}>{verboQPRef.current}{'\u00A0'}<span style={{ color: 'red', fontSize: '15px' }}>{verboQTRef.current}</span></h3>
                <h4 style={{marginTop: '0', marginBottom: '0', color: 'red'}}>{verboQIRef.current}</h4>
                <LazyLoad
                    height={200}
                    offset={[-200, 200]}
                    placeholder={<img src={require(`${logoRef.current}`)} alt="Imagem da conjugação"
                                      style={{width: '257px', height: '266px', objectFit: 'contain', objectPosition: 'center'}} />}
                >
                    <div style={{width: '257px', height: '262px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Image
                            src={process.env.PUBLIC_URL + `/drawable/${arquivoQRef.current}.png`}
                            alt="Imagem da conjugação"
                            onError={(e) => {e.target.onerror = null; e.target.src=require(`${logoRef.current}`)}}
                            style={{maxWidth: '100%',  height: '155px'}}
                        />
                    </div>
                </LazyLoad>
                <h5>{tempoQRef.current}</h5>
                <Table style={{ margin: '0' }}>
                    <tbody>
                    <tr>
                        <td>{'\u00A0'.repeat(9)}</td>
                        <td>{'\u00A0'.repeat(9)}</td>
                        <td style={{ display: 'flex', justifyContent: 'center' }}>
                            <span style={{ width: '10px' }}></span>
                            <Button id="irTempoQAnterior" onClick={handleTemposQAnteriorClick} style={{ width: '25px', height: '25px', fontSize: '18px', backgroundImage: `url(${previousIcon})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></Button>
                            <span style={{ width: '10px' }}></span>
                            <Button id="irTempoQPosterior" onClick={handleTemposQPosteriorClick} style={{ width: '25px', height: '25px', fontSize: '18px', backgroundImage: `url(${nextIcon})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></Button>
                        </td>
                        <td>
                            <label className="switch">
                                <input type="checkbox" id="passarTemposQ" checked={cBSPassarTempoQRef.current} onChange={handleSwitchTemposQChange}/>
                                <span className="slider round"></span>
                            </label>
                        </td>
                        <td>Passar T.</td>
                    </tr>
                    </tbody>
                </Table>
                <Table style={{ position: 'absolute', bottom: '1px'}}>
                    <tbody>
                    <tr>
                        <td style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button id="repetir" onClick={handleRepeatQClick} style={{ width: '40px', height: '40px', fontSize: '18px', backgroundImage: `url(${repeatIcon})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></Button>
                        </td>
                        <td> <Button id="sair" onClick={handleExitClick} style={{ width: '166px', height: '40px', fontSize: '20px',  backgroundPosition: 'center' }}>Sair</Button> </td>
                        <td style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button id="parar" onClick={handleStopQClick} style={{ width: '40px', height: '40px', fontSize: '18px', backgroundImage: `url(${stopIcon})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></Button>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>
            <div className="column right" style={{alignItems: 'center',  justifyContent: 'center', height: '100vh'}}>
                <table>
                    <tbody>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px', hyphens: "none"}}>
                            <h3><a href="https://www.youtube.com/channel/UCgT4TW_NGL6sFU744v0y_zQ" target="_blank" rel="noopener noreferrer">Canal:</a></h3>
                        </td>
                    </tr>
                    <tr style={{height: '5px'}}>

                        <td style={{height: '5px'}}>
                            <p><a href="https://www.youtube.com/channel/UCgT4TW_NGL6sFU744v0y_zQ" target="_blank" rel="noopener noreferrer">
                                <img src={require(`${logoRef.current}`)} alt="Ícone do Canal" style={{width: '200px', height: '200px'}}/>
                            </a></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ActivityFall;
