export const participios1 = [
    "p1a,\u00A0\u00A0\u00A0\u00A0\u00A0-\u00A0\u00A0\u00A0\u00A0\u00A0,\u00A0SER\u00A0-\u00A0TER\u00A0",
    "p2a,\u00A0\u00A0\u00A0\u00A0TER\u00A0\u00A0\u00A0\u00A0,\u00A0\u00A0\u00A0\u00A0SER\u00A0\u00A0\u00A0\u00A0",
    "p2b,\u00A0\u00A0\u00A0\u00A0TER\u00A0\u00A0\u00A0\u00A0,\u00A0\u00A0\u00A0\u00A0SER\u00A0\u00A0\u00A0\u00A0",
    "p3a,\u00A0\u00A0\u00A0\u00A0TER\u00A0\u00A0\u00A0\u00A0,\u00A0SER\u00A0-\u00A0TER?",
    "p4a,\u00A0TER\u00A0-\u00A0SER?,\u00A0\u00A0\u00A0\u00A0SER\u00A0\u00A0\u00A0\u00A0",
    "p4b,\u00A0TER\u00A0-\u00A0SER?,\u00A0\u00A0\u00A0\u00A0SER\u00A0\u00A0\u00A0\u00A0",
    "p4c,\u00A0TER\u00A0-\u00A0SER?,\u00A0\u00A0\u00A0\u00A0SER\u00A0\u00A0\u00A0\u00A0",
    "p5a,\u00A0TER\u00A0-\u00A0SER?,\u00A0SER\u00A0-\u00A0TER?",
];

export function Regulares(entrada) {
    const parts = entrada.split(",", 3);
    return parts[1];
}

export function Irregulares(entrada) {
    const parts = entrada.split(",", 3);
    return parts[2];
}