import React, {useState, useEffect, useRef} from 'react';
import { Form, Button, Table } from 'react-bootstrap';
// import paypalIcon from "./Icons/paypalIcon.png";

const ActivityConfig = ({ goBack }) => {

    const paypalIconRef=useRef('./Icons/paypalIcon.png');

    const [config, setConfig] = useState({
        NumeroAudios: '0',
        PaisAudio: 'Brasil',
        LerTempo: 'Uma Vez',
        VelocidadeAudio: '100'
    });
    // Este useEffect lida com a recuperação da configuração salva
    useEffect(() => {
        const savedConfig = localStorage.getItem('config');
        if (savedConfig) {
            setConfig(JSON.parse(savedConfig));
        }
    }, []); // Dependências vazias significam que isso só será executado na montagem do componente

    const handleConfigChange = (event) => {
        const newConfig = {
            ...config,
            [event.target.name]: event.target.value
        };
        console.log(newConfig);
        setConfig(newConfig);
    };

    const handleHelpClick2 = () => {
        window.open('https://www.youtube.com/watch?v=7nPxrnFnu5g', '_blank');
    };
    const handleSaveClick = () => {
        localStorage.setItem('config', JSON.stringify(config));
        goBack();
    };

    return (
        <div className="App">
            <div className="column left" style={{alignItems: 'center',  justifyContent: 'center', height: '100vh'}}>
                <table>
                    <tbody>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px', hyphens: "none"}}>
                            <h3><a href="https://www.paypal.com/donate/?hosted_button_id=T5WKS4MVY7VDE" target="_blank" rel="noopener noreferrer">Doação:</a></h3>
                        </td>
                    </tr>
                    <tr style={{height: '5px'}}>

                        <td style={{height: '5px'}}>
                            <p><a href="https://www.paypal.com/donate/?hosted_button_id=T5WKS4MVY7VDE" target="_blank" rel="noopener noreferrer">
                                <img src={require(`${paypalIconRef.current}`)} alt="Ícone do PayPal" style={{width: '200px', height: '200px'}}/>
                            </a></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="column center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <h3 style={{marginTop: '0' }}>CONFIGURAÇÕES</h3>
                <Table style={{ marginTop: '100px' }}>
                    <tbody>
                    <tr>
                        <td style={{ verticalAlign: 'middle', padding: '10px 0' }}><Form.Label style={{ fontSize: '16px', fontWeight: 'bold' }}>Número de Áudios:{'\u00A0'}</Form.Label></td>
                        <td style={{ verticalAlign: 'middle', padding: '10px 0' }}>
                            <Form.Select name="NumeroAudios" value={config.NumeroAudios} onChange={handleConfigChange} style={{ width: '100%', height: '30px', fontSize: '16px' }}>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </Form.Select>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ verticalAlign: 'middle', padding: '10px 0' }}><Form.Label style={{ fontSize: '16px', fontWeight: 'bold' }}>País do Áudio:</Form.Label></td>
                        <td style={{ verticalAlign: 'middle', padding: '10px 0' }}>
                            <Form.Select name="PaisAudio" value={config.PaisAudio} onChange={handleConfigChange} style={{ width: '100%', height: '30px', fontSize: '16px' }}>
                                <option value="Brasil">Brasil</option>
                                <option value="Portugal">Portugal</option>
                            </Form.Select>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ verticalAlign: 'middle', padding: '10px 0' }}><Form.Label style={{ fontSize: '16px', fontWeight: 'bold' }}>Ler Tempo:</Form.Label></td>
                        <td style={{ verticalAlign: 'middle', padding: '10px 0' }}>
                            <Form.Select name="LerTempo" value={config.LerTempo} onChange={handleConfigChange} style={{ width: '100%', height: '30px', fontSize: '16px' }}>
                                <option value="Nunca">Nunca</option>
                                <option value="Uma Vez">Uma Vez</option>
                                <option value="Sempre">Sempre</option>
                            </Form.Select>
                        </td>
                    </tr>
                    </tbody>
                </Table>
                <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0'}}>Velocidade do{'\u00A0'}Áudio:{'\u00A0'}{config.VelocidadeAudio}%</p>
                <input
                    name="VelocidadeAudio"
                    type="range"
                    min={50}
                    max={150}
                    value={config.VelocidadeAudio}
                    onChange={handleConfigChange}
                    style={{width: '150px', height: '30px', marginTop:'0'}}
                />
                <Button onClick={handleHelpClick2} style={{position: 'absolute', bottom: '50px',width: '250px', height: '40px', fontSize: '20px' }}>Ajuda</Button>
                <Button onClick={handleSaveClick} style={{position: 'absolute', bottom: '5px', width: '250px', height: '40px', fontSize: '20px'}}>Salvar e Sair</Button>
            </div>
            <div className="column right">
                <table>
                    <tbody>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px', hyphens: "none"}}>
                            <h3 style={{ marginBottom: '0'}}>Número de Áudios:</h3>
                        </td>
                    </tr>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px'}}>
                            <p style={{ marginBottom: '0', marginTop: '0'}}>Número de vezes que deseja ouvir a conjugação dos verbos.</p>
                        </td>
                    </tr>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px', hyphens: "none"}}>
                            <h3 style={{ marginBottom: '0'}}>País do Áudio:</h3>
                        </td>
                    </tr>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px'}}>
                            <p style={{ marginBottom: '0', marginTop: '0'}}>País de referência: A pronúncia do áudio e a nomenclatura gramatical adaptar-se-ão ao país escolhido. No caso, de haver mais de uma forma verbal, os áudios mostrarão as mais usadas nesse país.</p>
                        </td>
                    </tr>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px', hyphens: "none"}}>
                            <h3 style={{ marginBottom: '0'}}>Ler Tempo:</h3>
                        </td>
                    </tr>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px'}}>
                            <p style={{ marginBottom: '0', marginTop: '0'}}>Se você não desejar que o tempo do verbo seja lido antes da conjugação, deve escolher a opção "Nunca". No entanto, se quiser ouvir no ínicio de todas as conjugações, deve escolher "Uma Vez". Já deve escolher "Sempre" se desejar que o tempo seja falado antes de cada conjugação.</p>
                        </td>

                    </tr>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px', hyphens: "none"}}>
                            <h3 style={{ marginBottom: '0'}}>Velocidade do Áudio:</h3>
                        </td>
                    </tr>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px'}}>
                            <p style={{ marginBottom: '0', marginTop: '0'}}>Para alguns principiantes, é difícil de entender os áudios à velocidade normal. Eles poderão ouvi-los de forma mais lenta, colocando uma velocidade inferior a 100%. Por outro lado, alguns mais avançados e com falta de tempo, preferem ouvir os áudios de forma um pouco acelerada, o que conseguirão escolhendo uma velocidade superior a 100%.</p>
                        </td>

                    </tr>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px', hyphens: "none"}}>
                            <h3 style={{ marginBottom: '0'}}>Ajuda:</h3>
                        </td>
                    </tr>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px'}}>
                            <p style={{ marginBottom: '0', marginTop: '0'}}>Acesso a vídeo explicativo destas configurações, localizado no nosso canal do YouTube.</p>
                        </td>
                    </tr>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px', hyphens: "none"}}>
                            <h3 style={{ marginBottom: '0'}}>Salvar e Sair:</h3>
                        </td>
                    </tr>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px'}}>
                            <p style={{ marginBottom: '0', marginTop: '0'}}>Volta ao menu anterior, salvando as configurações.</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

    );
};

export default ActivityConfig;
