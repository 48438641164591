export const verbosq1 = [
    "e1_1,QUERER (want) (-ERER),PRESENTE",
    "e1_2,QUERER (want) (-ERER),IMPERATIVO RESTRITO",
    "e2_1,TRAZER (bring) (-AZER),PRESENTE",
    "e2_2,TRAZER (bring) (-AZER),IMPERATIVO RESTRITO",
    "e3_1,DIZER (say) (-IZER),PRESENTE",
    "e3_2,DIZER (say) (-IZER),IMPERATIVO RESTRITO",
    "e4_1,DEDUZIR (deduct) (-UZIR),PRESENTE",
    "e4_2,DEDUZIR (deduct) (-UZIR),IMPERATIVO RESTRITO",
];

export const temposq1 = [
    "1_e1,QUERER (want) (-ERER),PRESENTE",
    "1_e2,TRAZER (bring) (-AZER),PRESENTE",
    "1_e3,DIZER (say) (-IZER),PRESENTE",
    "1_e4,DEDUZIR (deduct) (-UZIR),PRESENTE",
    "2_e1,QUERER (want) (-ERER),IMPERATIVO RESTRITO",
    "2_e2,TRAZER (bring) (-AZER),IMPERATIVO RESTRITO",
    "2_e3,DIZER (say) (-IZER),IMPERATIVO RESTRITO",
    "2_e4,DEDUZIR (deduct) (-UZIR),IMPERATIVO RESTRITO",
];

export function VerbosQ(entrada) {
    const parts = entrada.split(",", 3);
    return parts[1];
}

export function TemposQ(entrada) {
    const parts = entrada.split(",", 3);
    return parts[2];
}

export function VerbosTemposQ(entrada) {
    const partes = entrada.split(",");
    const subPartes = partes[0].split("_");
    return `${subPartes[1]}_${subPartes[0]},${partes[1]},${partes[2]}`;
}
