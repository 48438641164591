import React, {useState, useEffect, useRef} from 'react';
import LazyLoad from 'react-lazyload';
import { Button, Table, Image } from 'react-bootstrap';
import './styles.css'; // Importe o arquivo CSS
import previousIcon from './Icons/previousIcon.png'; // O caminho para o ícone anterior
import nextIcon from './Icons/nextIcon.png'; // O caminho para o ícone próximo
import repeatIcon from './Icons/repeatIcon.png';
import stopIcon from './Icons/stopIcon.png';
import { verbos1, verbos2, verbos3, verbos4, verbos5, verbos6, verbos7, tempos1, tempos2, tempos3, tempos4, tempos5, tempos6, tempos7, Verbos, Tempos, VerbosTempos, CorrigirTempo } from './conjugacao1';
import {paradigmas, Terminacoes, Pagina, VerbosTemposParaVerbosS} from "./paradigmas";
// import logo from './logotipo2.png'; // O caminho para a imagem
// import paypalIcon from "./Icons/paypalIcon.png";

const ActivityBoard = ({ goBack, selection }) => {
    const logoRef=useRef('./logotipo2.png');
    const paypalIconRef=useRef('./Icons/paypalIcon.png');

    ////Variáveis do quadro principal
    const ordemVerbosRef = useRef(0);
    const ordemTemposRef = useRef(0);
    const titleRef = useRef('Regulares e Irregulares');
    const numeroTemposRef = useRef(0);
    const numeroVerbosRef = useRef(0);
    const verboRef = useRef('AMAR (To love)');
    const verboPRef =useRef('AMAR');
    const verboIRef =useRef('(To love)');
    const verboTRef =useRef ('(-AR)');
    const tempoRef = useRef('Presente');
    const arquivoRef = useRef("logotipo2");
    const cBFixarVerboRef = useRef(false);
    const cBFixarTempoRef = useRef(false);
    const cBSPassarVerboRef = useRef(false);
    const cBSPassarTempoRef = useRef(false);
    const conjugacaoVerbosRef = useRef(verbos1);
    const conjugacaoTemposRef = useRef(tempos1);
    const audiosUrlRef = useRef([]); // Adicione esta referência no início do seu componente
    const audiosRef = useRef([]);
    const currentAudioIndexRef = useRef(0);
    //// Variáveis do quadro de seguidores de paradigmas
    const arquivoSRef = useRef("logotipo2");
    const verboSRef = useRef('-AR');
    const paginaSRef = useRef('(1/1)');
    const listaPVerbosSRef =useRef(paradigmas);
    const listaVerboSRef = useRef(paradigmas);
    const ordemVerbosSRef = useRef(0);
    //// Variáveis de ambos os quadros
    const [renderSt, setRender] = useState(0);
    const loadingRef = useRef(false);
    const hasRunRef = useRef(false);
    const tempoDesabilitadoRef = useRef(1000);


    //// Funções do quadro principal
    const atualizarVetorVerbosS = () => {
        let verboParadigma= VerbosTemposParaVerbosS(conjugacaoVerbosRef.current[ordemVerbosRef.current]).substring(0,3);
        listaVerboSRef.current=listaPVerbosSRef.current.filter(item=> item.startsWith(verboParadigma));
    }

    // Função para atualizar os tempos
    const atualizarTempos =() => {
        if (ordemVerbosRef.current >= conjugacaoVerbosRef.current.length) {
            ordemVerbosRef.current=0;
        }
        arquivoRef.current=conjugacaoVerbosRef.current[ordemVerbosRef.current].substring(0, 5);

        verboRef.current=Verbos(conjugacaoVerbosRef.current[ordemVerbosRef.current]);
        verboPRef.current=verboRef.current.substring(0,verboRef.current.indexOf(" "));
        verboIRef.current=verboRef.current.substring(verboRef.current.indexOf(" ")+1);
        verboTRef.current=verboIRef.current.substring(verboIRef.current.indexOf(" ")+1);
        verboIRef.current=verboIRef.current.substring(0,verboIRef.current.indexOf(" "));
        tempoRef.current=CorrigirTempo(Tempos(conjugacaoVerbosRef.current[ordemVerbosRef.current]));

        // Atualize OrdemTempos

        ordemTemposRef.current= conjugacaoTemposRef.current.findIndex(tempoX => VerbosTempos(tempoX) === conjugacaoVerbosRef.current[ordemVerbosRef.current]);
        atualizarVetorVerbosS();
        ordemVerbosSRef.current=listaVerboSRef.current.findIndex(verboSX => verboSX.substring(0,3)===VerbosTemposParaVerbosS(conjugacaoVerbosRef.current[ordemVerbosRef.current]).substring(0,3));
    };

    // Função para atualizar os verbos
    const atualizarVerbos = () => {
        if (ordemTemposRef.current >= conjugacaoTemposRef.current.length) {
            ordemTemposRef.current = 0;
        }

        arquivoRef.current=VerbosTempos(conjugacaoTemposRef.current[ordemTemposRef.current]).substring(0, 5);
        verboRef.current=Verbos(conjugacaoTemposRef.current[ordemTemposRef.current]);
        verboPRef.current=verboRef.current.substring(0,verboRef.current.indexOf(" "));
        verboIRef.current=verboRef.current.substring(verboRef.current.indexOf(" ")+1);
        verboTRef.current=verboIRef.current.substring(verboIRef.current.indexOf(" ")+1);
        verboIRef.current=verboIRef.current.substring(0,verboIRef.current.indexOf(" "));
        tempoRef.current=CorrigirTempo(Tempos(conjugacaoTemposRef.current[ordemTemposRef.current]));

        // Atualize OrdemVerbos
        ordemVerbosRef.current = conjugacaoVerbosRef.current.findIndex(verboX => VerbosTempos(verboX) === conjugacaoTemposRef.current[ordemTemposRef.current]);
        atualizarVetorVerbosS();
        ordemVerbosSRef.current=listaVerboSRef.current.findIndex(verboSX => verboSX.substring(0,3)===VerbosTemposParaVerbosS(conjugacaoTemposRef.current[ordemTemposRef.current]).substring(0,3));
    };

    const pararAudios = () =>{
        if (currentAudioIndexRef.current < audiosRef.current.length){
            if (!audiosRef.current[currentAudioIndexRef.current].paused) {
                audiosRef.current[currentAudioIndexRef.current].pause();
                audiosRef.current[currentAudioIndexRef.current].currentTime = 0;
            }
            // Aguarda o término do áudio atual antes de tocar o próximo

            currentAudioIndexRef.current++;
            pararAudios();
        }
    }


    const tocarConjugacao = () => {
        let arquivoAudio, arquivoAudio0, urlAudio, urlAudio0,urlAudio00;
        let ActivityConfig = JSON.parse(localStorage.getItem('config'));
        if (ActivityConfig.PaisAudio === "Portugal"){
            arquivoAudio = "p" + arquivoRef.current;
            arquivoAudio0 = "p0" + arquivoRef.current;
        } else {
            arquivoAudio = "b" + arquivoRef.current;
            arquivoAudio0 = "b0" + arquivoRef.current;
        }

        urlAudio = process.env.PUBLIC_URL + `/raw/${arquivoAudio}.mp3`;
        urlAudio0 = process.env.PUBLIC_URL + `/raw/${arquivoAudio0}.mp3`;
        urlAudio00 = process.env.PUBLIC_URL + `/raw/virada.mp3`;
        // Defina audiosRef.current aqui
        const construirVetores = () => {
            let playCount=0;
            audiosUrlRef.current=[];
            audiosRef.current=[];
            while (playCount < ActivityConfig.NumeroAudios) {
                if(ActivityConfig.LerTempo==="Nunca"){
                    audiosUrlRef.current.push(urlAudio);
                } else if((ActivityConfig.LerTempo==="Uma Vez")&&playCount===0){
                    audiosUrlRef.current.push(urlAudio0,urlAudio);
                } else if((ActivityConfig.LerTempo==="Uma Vez")&&playCount>0){
                    audiosUrlRef.current.push(urlAudio);
                } else{
                    audiosUrlRef.current.push(urlAudio0,urlAudio);
                }
                playCount++;
            }
            console.log(3999);
            if (ActivityConfig.NumeroAudios==="0"){
                console.log(4000);
                audiosUrlRef.current=[urlAudio00];
                console.log(4001);
            }
            audiosRef.current = audiosUrlRef.current.map((url) => new Audio(url));
        };
        const tocarAudios = () => {
            if (currentAudioIndexRef.current < audiosRef.current.length) {
                let audioAtual = audiosRef.current[currentAudioIndexRef.current]
                audioAtual.playbackRate = ActivityConfig.VelocidadeAudio / 100;
                audioAtual.play();

                // Aguarda o término do áudio atual antes de tocar o próximo
                audioAtual.onended = function() {
                    currentAudioIndexRef.current++;
                    tocarAudios();
                };

            } else if (cBSPassarVerboRef.current === true) {
                document.getElementById("irVerboPosterior").click();
            } else if (cBSPassarTempoRef.current === true) {
                document.getElementById("irTempoPosterior").click();
            }

        };
        currentAudioIndexRef.current=0;
        pararAudios();
        construirVetores();
        currentAudioIndexRef.current=0;
        tocarAudios();

    };
    // eslint-disable-next-line react-hooks/exhaustive-deps

    ////Funções do quadro dos seguidores de paradigma

    const atualizarVerbosS = () => {
        console.log(11);
        atualizarVetorVerbosS();
        console.log(12);
        console.log(listaVerboSRef.current);
        console.log(ordemVerbosSRef.current);
        verboSRef.current = listaVerboSRef.current[ordemVerbosSRef.current];
        console.log(13);
        console.log(verboSRef.current);
        verboSRef.current = Terminacoes(verboSRef.current)+"\u00A0".repeat(4)+Pagina(verboSRef.current);
        console.log(14);
        arquivoSRef.current = listaVerboSRef.current[ordemVerbosSRef.current].substring(0,5);
        console.log(15);
        paginaSRef.current = Pagina(listaVerboSRef.current[ordemVerbosSRef.current]);
        console.log(16);
        ordemVerbosSRef.current = listaVerboSRef.current.findIndex(paradigmaX => paradigmaX === listaVerboSRef.current[ordemVerbosSRef.current]);
        console.log(17);
    }

    ////Funções para ambos os quadros
    const aplicarMudanca = () => {
        switch (selection) {
            case 1:
                titleRef.current="REGULARES BÁSICOS";
                conjugacaoVerbosRef.current=verbos1;
                numeroTemposRef.current=12;
                conjugacaoTemposRef.current=tempos1;
                numeroVerbosRef.current=3;
                break;
            case 2:
                titleRef.current="REGULARES PARTICULARES";
                conjugacaoVerbosRef.current=verbos2;
                numeroTemposRef.current=12;
                conjugacaoTemposRef.current=tempos2;
                numeroVerbosRef.current=5;
                break;
            case 3:
                titleRef.current="REGULARES ABUNDANTES";
                conjugacaoVerbosRef.current=verbos3;
                numeroTemposRef.current=12;
                conjugacaoTemposRef.current=tempos3;
                numeroVerbosRef.current=7;
                break;
            case 4:
                titleRef.current="IRREGULARES ESPECIAIS";
                conjugacaoVerbosRef.current=verbos4;
                numeroTemposRef.current=12;
                conjugacaoTemposRef.current=tempos4;
                numeroVerbosRef.current=8;
                break;
            case 5:
                titleRef.current="IRREGULARES FRACOS";
                conjugacaoVerbosRef.current=verbos5;
                numeroTemposRef.current=12;
                conjugacaoTemposRef.current=tempos5;
                numeroVerbosRef.current=9;
                break;
            case 6:
                titleRef.current="IRREGULARES FORTES";
                conjugacaoVerbosRef.current=verbos6;
                numeroTemposRef.current=12;
                conjugacaoTemposRef.current=tempos6;
                numeroVerbosRef.current=11;
                break;
            case 7:
                titleRef.current="IRREGULARES FORTÍSSIMOS";
                conjugacaoVerbosRef.current=verbos7;
                numeroTemposRef.current=12;
                conjugacaoTemposRef.current=tempos7;
                numeroVerbosRef.current=6;
                break;
            default:
                titleRef.current="Regulares e Irregulares";
        }

        // Chame as funções após a atualização do estado
        console.log(1);
        atualizarTempos();
        console.log(2);
        atualizarVerbosS();
        console.log(3);
        tocarConjugacao();
        console.log(4);
    }

    const forceRender = () => {
        //hasRunRef.current = false; // Atualize hasRunRef para false
        aplicarMudanca();
        setRender(renderSt + 1); // Força a renderização
    };

    const forceRenderLocal = () => {
        //hasRunRef.current = false; // Atualize hasRunRef para false
        setRender(renderSt + 1); // Força a renderização
    };

    //// Botões do quadro principal
    const handleTemposAnteriorClick = (saltar) => {
        let verboAnterior = Verbos(conjugacaoVerbosRef.current[ordemVerbosRef.current]);
        let newOrdemVerbos = ordemVerbosRef.current - 1;

        if (cBFixarVerboRef.current) {
            if (newOrdemVerbos < 0 || verboAnterior !== Verbos(conjugacaoVerbosRef.current[newOrdemVerbos])) {
                newOrdemVerbos += numeroTemposRef.current;
            }
        }

        if (newOrdemVerbos < 0) { newOrdemVerbos = conjugacaoVerbosRef.current.length - 1; }

        ordemVerbosRef.current= newOrdemVerbos;
        //atualizarTempos();
        if (saltar===true){
        } else{
            document.getElementById('irVerboPosterior').disabled=true;
            document.getElementById('irVerboAnterior').disabled=true;
            document.getElementById('irTempoPosterior').disabled=true;
            document.getElementById('irTempoAnterior').disabled=true;
            document.getElementById('sair').disabled=true;
            forceRender();
            setTimeout(() => {
                document.getElementById('irVerboPosterior').disabled=false;
                document.getElementById('irVerboAnterior').disabled=false;
                document.getElementById('irTempoPosterior').disabled=false;
                document.getElementById('irTempoAnterior').disabled=false;
                document.getElementById('sair').disabled=false;
            }, tempoDesabilitadoRef.current);
        }
    };

    const handleTemposPosteriorClick = (saltar) => {
        let verboAnterior = Verbos(conjugacaoVerbosRef.current[ordemVerbosRef.current]);
        let newOrdemVerbos = ordemVerbosRef.current + 1;
        if (cBFixarVerboRef.current) {
            if (newOrdemVerbos >= conjugacaoVerbosRef.current.length || verboAnterior !== Verbos(conjugacaoVerbosRef.current[newOrdemVerbos])) {
                newOrdemVerbos -= numeroTemposRef.current;
            }
        }
        if (newOrdemVerbos >= conjugacaoVerbosRef.current.length) { newOrdemVerbos = 0; }

        ordemVerbosRef.current= newOrdemVerbos;
        if (saltar===true){
        } else{
            document.getElementById('irVerboPosterior').disabled=true;
            document.getElementById('irVerboAnterior').disabled=true;
            document.getElementById('irTempoPosterior').disabled=true;
            document.getElementById('irTempoAnterior').disabled=true;
            document.getElementById('sair').disabled=true;
            forceRender();
            setTimeout(() => {
                document.getElementById('irVerboPosterior').disabled=false;
                document.getElementById('irVerboAnterior').disabled=false;
                document.getElementById('irTempoPosterior').disabled=false;
                document.getElementById('irTempoAnterior').disabled=false;
                document.getElementById('sair').disabled=false;
            }, tempoDesabilitadoRef.current);
        }
    };

    const handleVerbosAnteriorClick = (saltar) => {
        let tempoAnterior = Tempos(conjugacaoTemposRef.current[ordemTemposRef.current]);
        let newOrdemTempos = ordemTemposRef.current - 1;

        if (cBFixarTempoRef.current) {
            if (newOrdemTempos < 0 || tempoAnterior !== Tempos(conjugacaoTemposRef.current[newOrdemTempos])) {
                newOrdemTempos += numeroVerbosRef.current;
            }
        }

        if (newOrdemTempos < 0) { newOrdemTempos = conjugacaoTemposRef.current.length - 1; }

        ordemTemposRef.current= newOrdemTempos;
        atualizarVerbos();
        if (saltar===true){
        } else{
            document.getElementById('irVerboPosterior').disabled=true;
            document.getElementById('irVerboAnterior').disabled=true;
            document.getElementById('irTempoPosterior').disabled=true;
            document.getElementById('irTempoAnterior').disabled=true;
            document.getElementById('sair').disabled=true;
            forceRender();
            setTimeout(() => {
                document.getElementById('irVerboPosterior').disabled=false;
                document.getElementById('irVerboAnterior').disabled=false;
                document.getElementById('irTempoPosterior').disabled=false;
                document.getElementById('irTempoAnterior').disabled=false;
                document.getElementById('sair').disabled=false;
            }, tempoDesabilitadoRef.current);
        }
    };

    const handleVerbosPosteriorClick = (saltar) => {
        let tempoAnterior = Tempos(conjugacaoTemposRef.current[ordemTemposRef.current]);
        let newOrdemTempos = ordemTemposRef.current + 1;

        if (cBFixarTempoRef.current) {
            if (newOrdemTempos >= conjugacaoTemposRef.current.length || tempoAnterior !== Tempos(conjugacaoTemposRef.current[newOrdemTempos])) {
                newOrdemTempos -= numeroVerbosRef.current;
            }
        }

        if (newOrdemTempos >= conjugacaoTemposRef.current.length) { newOrdemTempos = 0; }

        ordemTemposRef.current= newOrdemTempos;
        atualizarVerbos();
        if (saltar===true){
        } else{
            document.getElementById('irVerboPosterior').disabled=true;
            document.getElementById('irVerboAnterior').disabled=true;
            document.getElementById('irTempoPosterior').disabled=true;
            document.getElementById('irTempoAnterior').disabled=true;
            document.getElementById('sair').disabled=true;
            forceRender();
            setTimeout(() => {
                document.getElementById('irVerboPosterior').disabled=false;
                document.getElementById('irVerboAnterior').disabled=false;
                document.getElementById('irTempoPosterior').disabled=false;
                document.getElementById('irTempoAnterior').disabled=false;
                document.getElementById('sair').disabled=false;
            }, tempoDesabilitadoRef.current);
        }
    };

    const handleCheckboxVerbosChange = () => {
        cBFixarVerboRef.current = !cBFixarVerboRef.current;
        forceRenderLocal();
    };

    const handleCheckboxTemposChange = () => {
        cBFixarTempoRef.current = !cBFixarTempoRef.current;
        forceRenderLocal();
    };

    const handleSwitchVerbosChange = () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks

        cBSPassarVerboRef.current=!cBSPassarVerboRef.current;
        forceRenderLocal();
        if(cBSPassarVerboRef.current === true) {
            cBSPassarTempoRef.current=false;
            pararAudios();
            handleVerbosAnteriorClick(true);
            handleVerbosPosteriorClick();
        }
    };

    const handleSwitchTemposChange = () => {
        cBSPassarTempoRef.current=!cBSPassarTempoRef.current;
        forceRenderLocal();
        if(cBSPassarTempoRef.current === true) {
            cBSPassarVerboRef.current=false;
            pararAudios();
            handleTemposAnteriorClick(true);
            handleTemposPosteriorClick();
        }
    };

    const handleExitClick = () => {
        currentAudioIndexRef.current=0
        pararAudios();
        goBack();
    };

    const handleRepeatClick = () => {
        tocarConjugacao();
    }

    const handleStopClick = () => {
        pararAudios();
    }

    //Botões dos seguidores de paradigma
    const handleSeguidorAnteriorClick = () => {
        let newOrdemVerbosS =ordemVerbosSRef.current - 1;

        if (newOrdemVerbosS < 0) { newOrdemVerbosS = listaVerboSRef.current.length - 1; }

        ordemVerbosSRef.current= newOrdemVerbosS;
        atualizarVerbosS();
        forceRenderLocal();
    };

    const handleSeguidorPosteriorClick = () => {
        let newOrdemVerbosS =ordemVerbosSRef.current + 1;

        if (newOrdemVerbosS>= listaVerboSRef.current.length) { newOrdemVerbosS = 0; }

        ordemVerbosSRef.current= newOrdemVerbosS;
        atualizarVerbosS();
        forceRenderLocal();
    };

    // Atualize os estados com base na seleção quando o componente for montado ou a seleção mudar
    useEffect(() => {
        if (!hasRunRef.current) {
            forceRender();
            hasRunRef.current = true;
        }
        loadingRef.current=true;
    });

    if (loadingRef.current===false) {
        return null; // Ou seu componente de carregamento personalizado
    }

    return (
        <div className="App">
            <div className="column left" style={{alignItems: 'center',  justifyContent: 'center', height: '100vh'}}>
                <table>
                    <tbody>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px', hyphens: "none"}}>
                            <h3><a href="https://www.paypal.com/donate/?hosted_button_id=T5WKS4MVY7VDE" target="_blank" rel="noopener noreferrer">Doação:</a></h3>
                        </td>
                    </tr>
                    <tr style={{height: '5px'}}>

                        <td style={{height: '5px'}}>
                            <p><a href="https://www.paypal.com/donate/?hosted_button_id=T5WKS4MVY7VDE" target="_blank" rel="noopener noreferrer">
                                <img src={require(`${paypalIconRef.current}`)}  alt="Ícone do PayPal" style={{width: '200px', height: '200px'}}/>
                            </a></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="column center2"  style={{flexDirection: 'column', alignItems: 'center'}}>
                <h3 style= {{marginTop: '0', marginBottom: '0'}}>{verboSRef.current}</h3>
                <Image
                    src={process.env.PUBLIC_URL + `/drawable2/${arquivoSRef.current}.png`}
                    alt="Paradigmas"
                    onError={(e) => {e.target.onerror = null; e.target.src=require(`${logoRef.current}`)}}
                    style={{width: '260px', height: '459px', position: 'absolute', bottom: '45px'}}
                />
                <Table style={{position: 'absolute', bottom: '3px'}}>
                    <tbody>
                    <tr>
                        <td>
                            <Button id="irSeguidorAnterior" onClick={handleSeguidorAnteriorClick} style={{ width: '25px', height: '25px', fontSize: '18px', marginRight: '20px', backgroundImage: `url(${previousIcon})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></Button>
                        </td>
                        <td>
                            <Button id="irSeguidorPosterior" onClick={handleSeguidorPosteriorClick} style={{ width: '25px', height: '25px', fontSize: '18px', backgroundImage: `url(${nextIcon})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></Button>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>
            <div className="column center"  style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <h3 style={{marginTop: '0' }}>{titleRef.current}</h3> {/* Use o título baseado na seleção */}
                <Table style={{ margin: '0' }}>
                    <tbody>
                    <tr>
                        <td> <input type="checkbox" id="fixarVerbo" checked={cBFixarVerboRef.current} onChange={handleCheckboxVerbosChange} /></td>
                        <td>Fixar V.</td>
                        <td style={{ display: 'flex', justifyContent: 'center' }}>
                            <span style={{ width: '10px' }}></span>
                            <Button id="irVerboAnterior" onClick={handleVerbosAnteriorClick} style={{ width: '25px', height: '25px', fontSize: '18px', backgroundImage: `url(${previousIcon})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></Button>
                            <span style={{ width: '10px' }}></span>
                            <Button id="irVerboPosterior" onClick={handleVerbosPosteriorClick} style={{ width: '25px', height: '25px', fontSize: '18px', backgroundImage: `url(${nextIcon})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></Button>
                        </td>
                        <td style={{verticalAlign: 'middle'}}>
                            <label className="switch">
                                <input type="checkbox" id="passarVerbos" checked={cBSPassarVerboRef.current} onChange={handleSwitchVerbosChange} />
                                <span className="slider round"></span>
                            </label>
                        </td>
                        <td>Passar V.</td>
                    </tr>
                    </tbody>
                </Table>
                <h3 style={{marginBottom: '0'}}>{verboPRef.current}{'\u00A0'}<span style={{ color: 'red', fontSize: '15px' }}>{verboTRef.current}</span></h3>
                <h4 style={{marginTop: '0', marginBottom: '0', color: 'red'}}>{verboIRef.current}</h4>
                <LazyLoad
                    height={200}
                    offset={[-200, 200]}
                    placeholder={<img src={require(`${logoRef.current}`)} alt="Imagem da conjugação"
                                      style={{width: '257px', height: '266px', objectFit: 'contain', objectPosition: 'center'}} />}
                >
                    <div style={{width: '254px', height: '262px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Image
                            src={process.env.PUBLIC_URL + `/drawable/${arquivoRef.current}.png`}
                            alt="Imagem da conjugação"
                            onError={(e) => {e.target.onerror = null; e.target.src=require(`${logoRef.current}`)}}
                            style={{maxWidth: '100%', height: '155px'}}
                        />
                    </div>
                </LazyLoad>
                <h5>{tempoRef.current}</h5>
                <Table style={{ margin: '0' }}>
                    <tbody>
                    <tr>
                        <td><input type="checkbox" id="fixarTempo" checked={cBFixarTempoRef.current} onChange={handleCheckboxTemposChange} /></td>
                        <td>Fixar T.</td>
                        <td style={{ display: 'flex', justifyContent: 'center' }}>
                            <span style={{ width: '10px' }}></span>
                            <Button id="irTempoAnterior" onClick={handleTemposAnteriorClick} style={{ width: '25px', height: '25px', fontSize: '18px', backgroundImage: `url(${previousIcon})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></Button>
                            <span style={{ width: '10px' }}></span>
                            <Button id="irTempoPosterior" onClick={handleTemposPosteriorClick} style={{ width: '25px', height: '25px', fontSize: '18px', backgroundImage: `url(${nextIcon})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></Button>
                        </td>
                        <td>
                            <label className="switch">
                                <input type="checkbox" id="passarTempos" checked={cBSPassarTempoRef.current} onChange={handleSwitchTemposChange}/>
                                <span className="slider round"></span>
                            </label>
                        </td>
                        <td>Passar T.</td>
                    </tr>
                    </tbody>
                </Table>
                <Table style={{ position: 'absolute', bottom: '1px'}}>
                    <tbody>
                    <tr>
                        <td style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button id="repetir" onClick={handleRepeatClick} style={{ width: '40px', height: '40px', fontSize: '18px', backgroundImage: `url(${repeatIcon})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></Button>
                        </td>
                        <td> <Button id="sair" onClick={handleExitClick} style={{ width: '166px', height: '40px', fontSize: '20px',  backgroundPosition: 'center' }}>Sair</Button> </td>
                        <td style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button id="parar" onClick={handleStopClick} style={{ width: '40px', height: '40px', fontSize: '18px', backgroundImage: `url(${stopIcon})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></Button>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>
            <div className="column right" style={{alignItems: 'center',  justifyContent: 'center', height: '100vh'}}>
                <table>
                    <tbody>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px', hyphens: "none"}}>
                            <h3><a href="https://www.youtube.com/channel/UCgT4TW_NGL6sFU744v0y_zQ" target="_blank" rel="noopener noreferrer">Canal:</a></h3>
                        </td>
                    </tr>
                    <tr style={{height: '5px'}}>

                        <td style={{height: '5px'}}>
                            <p><a href="https://www.youtube.com/channel/UCgT4TW_NGL6sFU744v0y_zQ" target="_blank" rel="noopener noreferrer">
                                <img src={require(`${logoRef.current}`)} alt="Ícone do Canal" style={{width: '200px', height: '200px'}}/>
                            </a></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ActivityBoard;
