export const paradigmas = [
    "s11P1,-AR,(1/2)",
    "s11P2,-AR,(2/2)",
    "s12P1,-ER,(1/2)",
    "s12P2,-ER,(2/2)",
    "s13P1,-IR,(1/2)",
    "s13P2,-IR,(2/2)",
    "s21P1,-EAR,(1/1)",
    "s22P1,-OER,(1/1)",
    "s23P1,-AIR,(1/1)",
    "s24P1,-UIR,(1/1)",
    "s25P1,-GUIR,(1/1)",
    "s30P1,-AGUAR,(1/1)",
    "s31P1,-EQUAR,(1/1)",
    "s32P1,-INQ(G)UIR,(1/1)",
    "s33P1,-IAR,(1/1)",
    "s34P1,-UIRª,(1/1)",
    "s35P1,-ERGIR,(1/1)",
    "s36P1,-O...IR,(1/1)",
    "s41P1,-IAR¹,(1/1)",
    "s42P1,-E...IR¹,(1/2)",
    "s42P2,-E...IR¹,(2/2)",
    "s43P1,-E...IR²,(1/1)",
    "s44P1,-I...IR¹,(1/1)",
    "s45P1,-O...IR¹,(1/1)",
    "s46P1,-O...IR²,(1/1)",
    "s47P1,-U...IR¹,(1/1)",
    "s48P1,-E...IR¹²,(1/1)",
    "s51P1,-PERDER,(1/1)",
    "s52P1,-VALER,(1/1)",
    "s53P1,-EDIR,(1/1)",
    "s54P1,-OUVIR,(1/1)",
    "s55P1,-PARIR,(1/1)",
    "s56P1,-REQUERER,(1/1)",
    "s57P1,-ER¹,(1/1)",
    "s58P1,-PROVER,(1/1)",
    "s59P1,-RIR,(1/1)",
    "s61P1,-PRAZER,(1/1)",
    "s62P1,-CABER,(1/1)",
    "s63P1,-DAR,(1/1)",
    "s64P1,-PODER,(1/1)",
    "s65P1,-VER,(1/1)",
    "s66P1,-DIZER,(1/1)",
    "s67P1,-FAZER,(1/1)",
    "s68P1,-TRAZER,(1/1)",
    "s69P1,-TER,(1/1)",
    "s6aP1,-VIR,(1/1)",
    "s6bP1,-POR,(1/3)",
    "s6bP2,-POR,(2/3)",
    "s6bP3,-POR,(3/3)",
    "s71P1,-QUERER,(1/1)",
    "s72P1,-ESTAR,(1/1)",
    "s73P1,-AVER,(1/1)",
    "s74P1,-SABER,(1/1)",
    "s75P1,-IR¹,(1/1)",
    "s76P1,-SER,(1/1)",
]

export const paradigmasq = [
    "t1P1,-ERER,(1/1)",
    "t2P1,-AZER,(1/1)",
    "t3P1,-IZER,(1/1)",
    "t4P1,-UZIR,(1/1)",
]

export const paradigmasp = [
    "u1P1,-ABRIR,(1/10)",
    "u1P2,-COBRIR,(2/10)",
    "u1P3,-DIZER,(3/10)",
    "u1P4,-ESCREVER,(4/10)",
    "u1P5,-FAZER,(5/10)",
    "u1P6,-POR,(6/10)",
    "u1P7,-POR,(7/10)",
    "u1P8,-POR,(8/10)",
    "u1P9,-VER,(9/10)",
    "u1Pa,-VIR,(10/10)",
    "u2P1,-ERGIR,(1/16)",
    "u2P2,-FIXAR,(2/16)",
    "u2P3,-MERGIR,(3/16)",
    "u2P4,-INCLUIR,(4/16)",
    "u2P5,-INQUIETAR,(5/16)",
    "u2P6,-ISENTAR,(6/16)",
    "u2P7,-LIBERTAR,(7/16)",
    "u2P8,-MANIFESTAR,(8/16)",
    "u2P9,-MORRER,(9/16)",
    "u2Pa,-ROMPER,(10/16)",
    "u2Pb,-SECAR,(11/16)",
    "u2Pc,-SEGURAR,(12/16)",
    "u2Pd,-SOLTAR,(13/16)",
    "u2Pe,-SUJAR,(14/16)",
    "u2Pf,-SUSPEITAR,(15/16)",
    "u2Pg,-VAGAR,(16/16)",
    "u3P1,-ELEGER,(1/5)",
    "u3P2,-FRIGIR,(2/5)",
    "u3P3,-GASTAR,(3/5)",
    "u3P4,-MATAR,(4/5)",
    "u3P5,-PAGAR,(5/5)",
    "u4P1,-ACEITAR,(1/20)",
    "u4P2,-ACENDER,(2/20)",
    "u4P3,-ASSENTAR,(3/20)",
    "u4P4,-BENZER,(4/20)",
    "u4P5,-COMPLETAR,(5/20)",
    "u4P6,-DISTINGUIR,(6/20)",
    "u4P7,-ENTREGAR,(7/20)",
    "u4P8,-ENXUGAR,(8/20)",
    "u4P9,-ERIGIR,(9/20)",
    "u4Pa,-EXPRESSAR,(10/20)",
    "u4Pb,-EXPULSAR,(11/20)",
    "u4Pc,-EXTINGUIR,(12/20)",
    "u4Pd,-FARTAR,(13/20)",
    "u4Pe,-FINDAR,(14/20)",
    "u4Pf,-PASMAR,(15/20)",
    "u4Pg,-PRENDER,(16/20)",
    "u4Ph,-REVOLVER,(17/20)",
    "u4Pi,-SURPREENDER,(18/20)",
    "u4Pj,-SUSPENDER,(19/20)",
    "u4Pk,-TINGIR,(20/20)",
    "u5P1,-ENVOLVER,(1/8)",
    "u5P2,-EXPRIMIR,(2/8)",
    "u5P3,-GANHAR,(3/8)",
    "u5P4,-IMPRIMIR,(4/8)",
    "u5P5,-INSERIR,(5/8)",
    "u5P6,-JUNTAR,(6/8)",
    "u5P7,-LIMPAR,(7/8)",
    "u5P8,-SALVAR,(8/8)",
]

export function Terminacoes(entrada) {
    const parts = entrada.split(",", 3);
    return parts[1];
}

export function Pagina(entrada) {
    const parts = entrada.split(",", 3);
    return parts[2];
}

export function VerbosTemposParaVerbosS(entrada) {
    let saida;
    const partes = entrada.split(",");
    const subPartes = partes[0].split("_");
    if (entrada.charAt(0)==="v"){
        saida= entrada;
    }else {
        saida=entrada
        saida = `${subPartes[1]}_${subPartes[0]},${partes[1]},${partes[2]}`;
    }
    saida = "s"+saida.substring(1,3)+"P1";
    return saida;
}

export function VerbosTemposParaVerbosQS(entrada) {
    let saida;
    const partes = entrada.split(",");
    const subPartes = partes[0].split("_");
    if (entrada.charAt(0)==="e"){
        saida= entrada;
    }else {
        saida=entrada
        saida = `${subPartes[1]}_${subPartes[0]},${partes[1]},${partes[2]}`;
    }
    saida = "t"+saida.substring(1,2)+"P1";
    return saida;
}

export function VerbosTemposParaVerbosPS(entrada) {
    let saida;
    const partes = entrada.split(",");
    const subPartes = partes[0].split("_");
    if (entrada.charAt(0)==="p"){
        saida= entrada;
    }else {
        saida=entrada
        saida = `${subPartes[1]}_${subPartes[0]},${partes[1]},${partes[2]}`;
    }
    saida = "u"+saida.substring(1,2)+"P1";
    return saida;
}
