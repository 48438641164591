import React, {useState} from 'react';
import { Button } from 'react-bootstrap';
import ActivityBoard from './board'; // Importe o componente ActivityBoard

const ActivityVerbs = ({ goBack }) => {
    const [page, setPage] = useState('verbs');
    const [selection, setSelection] = useState(null); // Adicione um estado para a seleção

    const handleBoardClick = (selection) => {
        // Defina a seleção e navegue para a página de tabuleiro
        setSelection(selection);
        setPage('board');
    };

    if (page === 'verbs') {
        return (
            <div className="App">
                <div className="column left">
                    <h2 style={{hyphens: "none", display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', marginBottom: 0 }}>Regulares</h2>
                    <table>
                        <tbody>
                        <tr style={{height: '5px'}}>
                            <td style={{height: '5px', hyphens: "none"}}>
                                <h3 style={{ marginBottom: '0'}}>Básicos:</h3>
                            </td>
                        </tr>
                        <tr style={{height: '5px'}}>
                            <td style={{height: '5px'}}>
                                <p style={{ marginBottom: '0', marginTop: '0'}}>Aqui mostraremos três verbos representantes das três conjugações: -AR,-ER,-IR</p>
                            </td>
                        </tr>
                        <tr style={{height: '5px'}}>
                            <td style={{height: '5px', hyphens: "none"}}>
                                <h3 style={{ marginBottom: '0'}}>Particulares:</h3>
                            </td>
                        </tr>
                        <tr style={{height: '5px'}}>
                            <td style={{height: '5px'}}>
                                <p style={{ marginBottom: '0', marginTop: '0'}}>Alguns verbos não seguem as terminações dos verbos regulares, no entanto, esses verbos podem ser considerados regulares, pois, todos que têm essas terminações não se comportam como os verbos regulares podendo ser possível estabelecer um novo padrão para essas novas terminações: -EAR, -OER, -AIR, -UIR, -GUIR. A primeira letra destas terminações pertence à raiz da palavra, que permanece em todas as conjugações. </p>
                            </td>
                        </tr>
                        <tr style={{height: '5px'}}>
                            <td style={{height: '5px', hyphens: "none"}}>
                                <h3 style={{ marginBottom: '0'}}>Abundantes:</h3>
                            </td>
                        </tr>
                        <tr style={{height: '5px'}}>
                            <td style={{height: '5px'}}>
                                <p style={{ marginBottom: '0', marginTop: '0'}}>Existem alguns verbos regulares que, para além da forma regular, têm um forma adicional, por vezes mais usada; e, por isso se chamam abundantes.</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="column center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h2 style={{marginTop: '0', marginBottom: '20px' }}>CONJUGAÇÕES</h2> {/* Adicione margem abaixo */}
                    <h3>REGULARES</h3>
                    <Button onClick={() => handleBoardClick(1)} style={{marginBottom: '5px', width: '250px', height: '40px', fontSize: '20px' }}>Básicos</Button>
                    <Button onClick={() => handleBoardClick(2)} style={{marginBottom: '5px', width: '250px', height: '40px', fontSize: '20px' }}>Particulares</Button>
                    <Button onClick={() => handleBoardClick(3)} style={{marginBottom: '5px',  width: '250px', height: '40px', fontSize: '20px' }}>Abundantes</Button> {/* Adicione margem abaixo */}
                    <h3 style={{position: 'absolute', bottom: '225px',}}>IRREGULARES</h3>
                    <Button onClick={() => handleBoardClick(4)} style={{ position: 'absolute', bottom: '185px', width: '250px', height: '40px', fontSize: '20px' }}>Especiais</Button>
                    <Button onClick={() => handleBoardClick(5)} style={{ position: 'absolute', bottom: '140px',width: '250px', height: '40px', fontSize: '20px' }}>Fracos</Button>
                    <Button onClick={() => handleBoardClick(6)} style={{ position: 'absolute', bottom: '95px',width: '250px', height: '40px', fontSize: '20px' }}>Fortes</Button>
                    <Button onClick={() => handleBoardClick(7)} style={{position: 'absolute', bottom: '50px', width: '250px', height: '40px', fontSize: '20px' }}>Fortíssimos</Button>
                    <Button onClick={goBack} style={{ position: 'absolute', bottom: '5px', width: '250px', height: '40px', fontSize: '20px' }}>Sair</Button>
                </div>
                <div className="column right">
                    <h2 style={{hyphens: "none", display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', marginBottom: 0 }}>Irregulares</h2>
                    <table>
                        <tbody>
                        <tr style={{height: '5px'}}>
                            <td style={{height: '5px', hyphens: "none"}}>
                                <h3 style={{ marginBottom: '0'}}>Especiais:</h3>
                            </td>
                        </tr>
                        <tr style={{height: '5px'}}>
                            <td style={{height: '5px'}}>
                                <p style={{ marginBottom: '0', marginTop: '0'}}>Embora estes verbos sejam considerados irregulares por haver vários padrões para a mesma terminação incluindo, por vezes, a regular; eles têm uma certa predominância. Muitas vezes a conjugação irregular é mais frequente do que a regular para aquela terminação, quando existe.</p>
                            </td>
                        </tr>
                        <tr style={{height: '5px'}}>
                            <td style={{height: '5px', hyphens: "none"}}>
                                <h3 style={{ marginBottom: '0'}}>Fracos:</h3>
                            </td>
                        </tr>
                        <tr style={{height: '5px'}}>
                            <td style={{height: '5px'}}>
                                <p style={{ marginBottom: '0', marginTop: '0'}}>Os verbos irregulares fracos têm irregularidade só no Presente e dos tempos que derivam deste: Presente do Conjuntivo (Subjuntivo) e Imperativo.</p>
                            </td>
                        </tr>
                        <tr style={{height: '5px'}}>
                            <td style={{height: '5px', hyphens: "none"}}>
                                <h3 style={{ marginBottom: '0'}}>Fortes:</h3>
                            </td>
                        </tr>
                        <tr style={{height: '5px'}}>
                            <td style={{height: '5px'}}>
                                <p style={{ marginBottom: '0', marginTop: '0'}}>Estes verbos, para além da eventual irregularidade no Presente do Indicativo, eles apresentam irregularidades no Pretérito Perfeito e nos tempos que derivam deste: Pretérito Mais{'\u00AD\u2011'}que{'\u00AD\u2011'}Perfeito, Pretérito Imperfeito do Conjuntivo (Subjuntivo) e Futuro do Conjuntivo (Subjuntivo). Alguns deles apresentam também irregularidades no Préterito Imperfeito e no Futuro.</p>
                            </td>
                        </tr>
                        <tr style={{height: '5px'}}>
                            <td style={{height: '5px', hyphens: "none"}}>
                                <h3 style={{ marginBottom: '0'}}>Fortíssimos:</h3>
                            </td>
                        </tr>
                        <tr style={{height: '5px'}}>
                            <td style={{height: '5px'}}>
                                <p style={{ marginBottom: '0', marginTop: '0'}}>Mesmo os verbos irregulares apresentam regras de derivação de uns tempos pelos outros. Estes verbos violam até essas regras e, por isso, são chamados de fortíssimos, pois desobedecem a regras que a maior parte dos verbos irregulares obedece.</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    } else if (page === 'board') {
        return <ActivityBoard selection={selection} goBack={() => setPage('verbs')} />; // Passe a seleção para o componente ActivityBoard
    }
};

export default ActivityVerbs;
